import React,{useState,useEffect,useContext} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';



import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '../App.css';


import Navbarmenu from './Navbarmenu';
import Home from './Home';
import Loginsection from './Loginsection';
import Resetpassword from './Resetpassword';
import Myaccount from './Myaccount';
import Logout from './Logout';
import Contmail from './Contmail';
import About from './About';
import Footer from './Footer';

import Bookadd from './Bookadd';
import Bookaddtwopreview from './Bookaddtwopreview';
import Bookpreview from './Bookpreview';
import Bookupgradepay from './Bookupgradepay';
import PaypalBookSuccess from './PaypalBookSuccess';
import StripeBookSuccess from './StripeBookSuccess';
import PaypalBookupgradeSuccess from './PaypalBookupgradeSuccess';
import StripeBookupgradeSuccess from './StripeBookupgradeSuccess';
import Cancel from './Cancel';


import AddAudio from './AddAudio';
import AddAudiopreview from './AddAudiopreview';
import PaypalAudioSuccess from './PaypalAudioSuccess';
import StripeAudioSuccess from './StripeAudioSuccess';
import AudioBookPreview from './AudioBookPreview';

import AddPublisher from './AddPublisher';
import AddPublisherpreview from './AddPublisherpreview';
import PaypalPublisherSuccess from './PaypalPublisherSuccess';
import StripePublisherSuccess from './StripePublisherSuccess';
import PublisherPreview from './PublisherPreview';

import AddBookTrailer from './AddBookTrailer';
import AddBookTrailerpreview from './AddBookTrailerpreview';
import PaypalvideoSuccess from './PaypalvideoSuccess';
import StripevidoSuccess from './StripevidoSuccess';
import BookTrailers from './BookTrailers';

import Pendingbook from './Pendingbook';
import Pendingaudio from './Pendingaudio';
import Pendingvideo from './Pendingvideo';
import Pendingpublisher from './Pendingpublisher';


import PriceUpdate from './PriceUpdate';
import Highlighted from './Highlighted';
import RecommendedBooks from './RecommendedBooks';
import FeaturedBooks from './FeaturedBooks';
import StandardBooks from './StandardBooks';
import AudioBooks from './AudioBooks';
import Publishers from './Publishers';
import BookTrailersPreview from './BookTrailersPreview';

import Adminbookapproval from './Adminbookapproval';
import Adminvideoapproval from './Adminvideoapproval';
import AdminAudioapproval from './AdminAudioapproval';
import Adminpublisherapproval from './Adminpublisherapproval';
import AdminFreeapproval from './AdminFreeapproval';




import PdfPreviewbook from './PdfPreviewbook';
import PdfPreviewaudio from './PdfPreviewaudio';
import PdfPreviewvideo from './PdfPreviewvideo';
import PdfPreviewpublisher from './PdfPreviewpublisher';

import Search from './Search';
import Demo from './Demo3';





import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)



function Dashboard() {
 

 


  return (
    <BrowserRouter basename="/">
      <ToastContainer />
        <div className="App">
           <div className="">
              <div className="row ">
                <Navbarmenu/>
                
              </div>
            </div>
            </div>
        
          <Switch>
    

         <Route exact path="/" component={Home}/>
         <Route exact path="/Login" component={Loginsection}/>
         <Route exact path="/ResetPassword/:passkey" component={Resetpassword}/>
         <Route exact path="/Myaccount" component={Myaccount}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/Contact" component={Contmail}/>
         <Route exact path="/About" component={About}/>
         <Route exact path="/PostYourBook" component={Bookadd}/>
       
         <Route exact path="/5ba3c8d60e477b46681416aa408156ad/:Addpreview" component={Bookaddtwopreview}/>
         <Route exact path="/Bookpreview/:Addpreview" component={Bookpreview}/>
         <Route exact path="/Bookupgradepay/:Addpreview" component={Bookupgradepay}/>
         <Route exact path="/StripeBookSuccess/:Addpreview" component={StripeBookSuccess}/>
         <Route exact path="/PaypalBookSuccess/:Addpreview" component={PaypalBookSuccess}/>
<Route exact path="/PaypalBookupgradeSuccess/:Addpreview" component={PaypalBookupgradeSuccess}/>
         <Route exact path="/StripeBookupgradeSuccess/:Addpreview" component={StripeBookupgradeSuccess}/>
         
         <Route exact path="/Cancel" component={Cancel}/>

         <Route exact path="/AddAudio" component={AddAudio}/>
         <Route exact path="/dc500506ebb067a6e40f7ccceaff4af0/:Addpreview" component={AddAudiopreview}/>
         <Route exact path="/StripeAudioSuccess/:Addpreview" component={StripeAudioSuccess}/>
         <Route exact path="/PaypalAudioSuccess/:Addpreview" component={PaypalAudioSuccess}/>
         <Route exact path="/AudioBookPreview/:Addpreview" component={AudioBookPreview}/>

         <Route exact path="/AddPublisher" component={AddPublisher}/>
         <Route exact path="/02b48f3ed729d18dcdb4cfe75c2ece5a/:Addpreview" component={AddPublisherpreview}/>
         <Route exact path="/StripePublisherSuccess/:Addpreview" component={StripePublisherSuccess}/>
         <Route exact path="/PaypalPublisherSuccess/:Addpreview" component={PaypalPublisherSuccess}/>
         <Route exact path="/PublisherPreview/:Addpreview" component={PublisherPreview}/>

         <Route exact path="/BookTrailers" component={BookTrailers}/>
         <Route exact path="/AddBookTrailer" component={AddBookTrailer}/>
         <Route exact path="/f93718ba896b0ec0ec82e0decfa8ece7/:Addpreview" component={AddBookTrailerpreview}/>
         <Route exact path="/StripevidoSuccess/:Addpreview" component={StripevidoSuccess}/>
         <Route exact path="/PaypalvideoSuccess/:Addpreview" component={PaypalvideoSuccess}/>

         <Route exact path="/BookPaymentPending/:bookviews" component={Pendingbook}/>
         <Route exact path="/AudioBookPaymentPending/:bookviews" component={Pendingaudio}/>
         <Route exact path="/BookTrailerPaymentPending/:bookviews" component={Pendingvideo}/>
         <Route exact path="/PublisherPaymentPending/:bookviews" component={Pendingpublisher}/>



         <Route exact path="/PriceUpdate" component={PriceUpdate}/>
         <Route exact path="/Highlighted" component={Highlighted}/>
         <Route exact path="/RecommendedBooks" component={RecommendedBooks}/>
         <Route exact path="/FeaturedBooks" component={FeaturedBooks}/>
         <Route exact path="/StandardBooks" component={StandardBooks}/>
       
         <Route exact path="/AudioBooks" component={AudioBooks}/>
         <Route exact path="/Publishers" component={Publishers}/>
         <Route exact path="/BookTrailersPreview/:Addpreview" component={BookTrailersPreview}/>

         <Route exact path="/Adminbookapproval" component={Adminbookapproval}/>
         <Route exact path="/Adminvideoapproval" component={Adminvideoapproval}/>
         <Route exact path="/AdminAudioapproval" component={AdminAudioapproval}/>
         <Route exact path="/Adminpublisherapproval" component={Adminpublisherapproval}/>
         <Route exact path="/AdminFreeapproval" component={AdminFreeapproval}/>


         <Route exact path="/PdfPreviewbook/:Addpreview" component={PdfPreviewbook}/>
         <Route exact path="/PdfPreviewaudio/:Addpreview" component={PdfPreviewaudio}/>
         <Route exact path="/PdfPreviewvideo/:Addpreview" component={PdfPreviewvideo}/>
         <Route exact path="/PdfPreviewpublisher/:Addpreview" component={PdfPreviewpublisher}/>

         <Route exact path="/Search" component={Search}/>
         <Route exact path="/Demo" component={Demo}/>
        
          </Switch>
        
  

      <Footer />

      
       
    </BrowserRouter>
  );
}

export default Dashboard;
