
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';


import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;

function About() {

       const {impressivelatestbook} = useContext(MyContext); 
       const [isimpresivelatest, setimpresivelatest] = useState([]);

    useEffect( () => {
       
    impressivelatest();
      }, []); 
   
    
      const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult.bookslatestimpressive);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
   }
   
        return(
        
        <div className="login-1 consssqqq ">
                <div className="text_shadowbook2w"></div>
             <div className="text_shadowbook2sw"></div>
                  <div className="container">
                    <div className="row">
                     
                        <div className="col-md-1">
                                &nbsp;

                        </div>
                        <div className="col-md-8 logsss3">
                            
                
                        </div>
                        <div className="col-md-3 lll">
                       <div className="aaa4">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`} target="_parent">
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                            
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div>
                    </div>
                  </div>
        </div>
        );
    
}
export default About;