import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;

function AddAudio(props) {

  let history = useHistory();

       
       const {rootState,pricelistall,newAudiobookadd,impressivelatestbook} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
       let editorState = EditorState.createEmpty();
       const [description, setDescription] = useState(editorState);
       const [isimpresivelatest, setimpresivelatest] = useState([]);
       const [userInfo, setuserInfo] = useState({
              title: '',
              written: '',
              narrated: '',
              hrs: '',
              mins: '',
              price: '',
              purchase: '',
              website: '',
              file:'',
                
       });

       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       
    const [isprice, setisprice] = useState({Audio:''});

       useEffect( () => {
              window.scrollTo(0, 0);
              impressivelatest();
                  pricelist();
       }, []); 
     
       const pricelist = async () => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data.priceall);
                     setisprice({ 
                            ...isprice,
                            Audio:data.priceall[4].price,
                     }) ;
              }
       }
       const impressivelatest = async() =>{
        try {
               const impressiveresult = await impressivelatestbook();
                      if(impressiveresult.success === 1){
                             console.log(impressiveresult.bookslatestimpressive);
                             setimpresivelatest(impressiveresult.bookslatestimpressive) ;
               }
        } catch (error) { throw error;}
    }

       const html = '';
       const contentBlock = htmlToDraft(html);
       if (contentBlock) {
         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         //const editorState = EditorState.createWithContent(contentState);
         //setDescription(editorState);
       }
       //text editior
       const onEditorStateChange = (editorState) => {
              setDescription(editorState);
            }
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

       const audioAddbooks = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                            const dataadd = await newAudiobookadd(userInfo,userInfo.description.value,theUser,isprice.Audio);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   history.push(`/dc500506ebb067a6e40f7ccceaff4af0/${dataadd.newids}`);
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

       
        return(
               <div className="bookads2">
                     <div className="container">
                            <div className="row">
                                
                                   <div className="col-md-9">

                                   <h3 className="yyy2"> Audio Book Information  </h3>
              <form onSubmit={audioAddbooks} className="central-meta">
              <span className="adverts-form-required2"> <b> * Denotes A Required Field </b></span>
              <div className="clearfix"></div>
              <div className="form-row">
              <h3 className="yyy2"> Onetime Fee: <span className="cols"> ${isprice.Audio} </span>   </h3>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Title" required />
                  </div>
                  <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Written by </label>
                          <input type="text" name="written" value={userInfo.written} onChange={onChangeValue} className="form-control" placeholder="Written by"  />
                      </div> 
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Narrated by  </label>
                          <input type="text" name="narrated" value={userInfo.narrated} onChange={onChangeValue} className="form-control" placeholder="Narrated by"  />
                      </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Audio Book Cover Image <span className="adverts-form-required"> * </span> </label>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                  </div>
                  {filetemp && (
                    <div style={{ textAlign: "center" }}>
                         <img className="previewimg"  src={filetemp} alt="UploadImage" />
                        <button onClick={resetFile} className="removeqqq">Remove File</button>
                    </div>
                    )}
                     {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                    <div className="form-group col-md-12 editor">
                    <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            
                        />
                     <textarea style={{display:'none'}}
                     disabled ref={(val) => userInfo.description = val}
                    
                     value={draftToHtml(convertToRaw(description.getCurrentContent())) }
                     />
                    </div>
                    <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Audio Length  </label>
                          <div className="clearfix"></div>
                          <div className="form-group col-md-6" style={{paddingLeft:0}}>
                             <input type="text" name="hrs" value={userInfo.hrs} onChange={onChangeValue} className="form-control" placeholder="hrs" />
                            </div>
                            <div className="form-group col-md-6" style={{paddingRight:0}}>
                             <input type="text" name="mins" value={userInfo.mins} onChange={onChangeValue} className="form-control" placeholder="mins" />
                            </div> 


                      </div> 
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Price  </label>
                      <input type="text" name="price" value={userInfo.price} onChange={onChangeValue}  className="form-control" placeholder="Price" />
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Audio Book Purchase Link  </label>
                      <input type="text" name="purchase" value={userInfo.purchase} onChange={onChangeValue}  className="form-control" placeholder="Book Purchase Link" />
                  </div>
                
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Author Website  </label>
                      <input type="text" name="website" value={userInfo.website} onChange={onChangeValue}   className="form-control" placeholder="Author Website" />
                  </div>     
                 
               
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="btn btn-primary"> Continue  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                  </div>
                   }
              
             
              </div>
              
          </form>
          </div>



          <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                             <div className="aaa4">
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads+item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                                   </div>
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 



          </div>
          </div>
          </div>
        );
    }

export default AddAudio;