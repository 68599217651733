import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoEdit from './VideoEdit';

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;



function BookTrailersPreview(props) {
    const {newvideoaddpreview,rootState,impressivelatestbook} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [isEditing, setisEditing] = useState(false);

    const [isimpresivelatest, setimpresivelatest] = useState([]);
    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
     
    impressivelatest();
}, []); 

const impressivelatest = async() =>{
 try {
        const impressiveresult = await impressivelatestbook();
               if(impressiveresult.success === 1){
                      console.log(impressiveresult.bookslatestimpressive);
                      setimpresivelatest(impressiveresult.bookslatestimpressive) ;
        }
 } catch (error) { throw error;}
}

   
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
              const dataaddpreview = await newvideoaddpreview(ids);
              if(dataaddpreview.success === 1){
                     console.log(dataaddpreview);
                     setisbookpreview(dataaddpreview.bookpreviewsall);
                   
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              booklistpreview(props.match.params.Addpreview);
       }
      
     



   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

   
        return(
       <div className="bookadspreview bookvissss">
              
              <div className="container">
                     <div className="row">
                            
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.video_id} className="col-md-9" style={{padding:0,}}> 
                            
                     {isEditing === true  ?
                             <VideoEdit video_id={props.match.params.Addpreview} title={item.title} url={item.url} editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            
                               
                             

                            <div className="col-md-12 yyy">
                            {theUser === item.user_id || theUser === 2  || theUser === 6 ? <>  

                            <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                            
                            {theUser === 2  || theUser === 6 ? <> 
                                   <Link to={`/PdfPreviewvideo/${item.video_id}`} style={{float:"right"}}>  <button className="thm-btn pricing-one__btn editsss" > Payment Receipt <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                                   </Link>
                          </>: null}
                          
                     <div className="clearfix"></div>

                   
                            <div className="col-md-12 zzz8">
  <h1 style={{textAlign:"center"}}>{item.title}</h1>
       {item.url.match(regExp) ? <> 
            {item.url.match(regExp).length >= 2 ? <>      
                            <iframe id="ytplayer" type="text/html" className="videosss"
                         src={`https://www.youtube.com/embed/`+item.url.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 

                          </>:null}
                          </>:null}
                          
                          </div>




                          </>: null}
                          </div>
                          
                            
                      </>
                     }
                    



                      </div>
                      ))}


<div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 


             </div>
             </div>
             </div>
        );
    }

export default BookTrailersPreview;