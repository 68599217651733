
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
import userphotos from '../Img/user_profile.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function DashboardAccount(props) {

       const loaderfeatured = [1,2,3,4,5,6,7,8];
       const {loginUserimage,viewuserprofile,mybookview,myaudioview,myvideoview,mypublisherview} = useContext(MyContext);
       const [loadinglogin, setloadinglogin] = useState(false);    

       const [userInfo, setuserInfo] = useState({file:'',filetemp:'' });
       const [userlist, setuserlist] = useState({userimg:[]});
       const [isupload, setisupload] = useState(false);
       const [isuploadloader, setisuploadloader] = useState(false);
       const [isname, setisname] = useState();

       const [invalidImage, setinvalidImage] = useState();

       const [isbook, setisbook] = useState([]);
       const [isaudio, setisaudio] = useState([]);
       const [isvideo, setisvideo] = useState([]);
       const [ispublisher, setispublisher] = useState([]);


       useEffect( () => {
             userdetails(props.storageid);
             mybook(props.storageid);
             mybookaudio(props.storageid);
             mybookvideo(props.storageid);
             mybookpublisher(props.storageid);
            }, []);


       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log(viewuser.memberlist);
                            setuserlist({
                                   ...userlist,
                                   userimg:[...viewuser.memberlist]
                            } ); 
                            setisname(viewuser.memberlist[0].name);
                            setisuploadloader(false)
                     }
              } catch (error) { throw error;}      
       }
       const mybook = async(storageid) =>{
              try {
                     const mybooklist = await mybookview(storageid);
                     if(mybooklist.success === 1){
                            console.log(mybooklist.booksuccess);
                            setisbook(mybooklist.booksuccess); 
                     }
              } catch (error) { throw error;}      
       }
       const mybookaudio = async(storageid) =>{
              try {
                     const myaudiolist = await myaudioview(storageid);
                     if(myaudiolist.success === 1){
                            console.log(myaudiolist.audiosuccess);
                            setisaudio(myaudiolist.audiosuccess); 
                     }
              } catch (error) { throw error;}      
       }
       const mybookvideo = async(storageid) =>{
              try {
                     const myvideolist = await myvideoview(storageid);
                     if(myvideolist.success === 1){
                            console.log(myvideolist.videosuccess);
                            setisvideo(myvideolist.videosuccess); 
                     }
              } catch (error) { throw error;}      
       }
       const mybookpublisher = async(storageid) =>{
              try {
                     const mypublisherlist = await mypublisherview(storageid);
                     if(mypublisherlist.success === 1){
                            console.log(mypublisherlist.publishersuccess);
                            setispublisher(mypublisherlist.publishersuccess); 
                     }
              } catch (error) { throw error;}      
       }

       const  editUploadMode =() =>{
              setisupload(true);
       }

       const resetFile = (event) => {
              event.preventDefault();
              setuserInfo({
                     ...userInfo,
                     filetemp: ''
                })
            }

            let reader = new FileReader();
   const handleChange = (event) => {


       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG,GIF');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 300;
                var MAX_HEIGHT = 300;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                     filetemp: URL.createObjectURL(imageFile)
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);
     };

const userphotosAdd = async(event) =>{
       try {
              setuserInfo({
                     ...userInfo,
                     filetemp: ''
                })
                
       
       setloadinglogin(true);
       event.preventDefault();
       event.persist();
       if(invalidImage !== null){
              toast.error(invalidImage, {
                position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              }); 
              setloadinglogin(false);
              return;
            }
            setisuploadloader(true);
            setisupload(false);
       const dataimage = await loginUserimage(userInfo.file,props.storageid);
       if(dataimage.success === 1){
           setloadinglogin(false);
          toast.success( dataimage, {
           position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
         });  
         userdetails(props.storageid);
        
         
       }else{
           setloadinglogin(false);
       }
} catch (error) { throw error;}    

}

const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

            return ( 
                   <>
             <h3 className="myaccount-content">Dashboard <span><FontAwesomeIcon icon={['fas','user']} className="iconsss2sss" />{isname}</span></h3>
             <div className="col-md-7 ppp" >

<ul className="timeline">

<li>
<div className="direction-r">
<div className="flag-wrapper">
       <span className="flag"> <Link to="/PostYourBook" className="base-btn1"> Post Your Book </Link></span>
</div>
</div>
</li>
<li>
<div className="direction-l">
<div className="flag-wrapper">
       <span className="flag"><Link to="/AddBookTrailer" className="base-btn1"> Add Book Trailer </Link></span>
</div>
</div>
</li>
<li>
<div className="direction-r">
<div className="flag-wrapper">
       <span className="flag"> <Link to="/AddPublisher" className="base-btn1">   Add Publisher</Link></span>
</div>
</div>
</li>
<li>
<div className="direction-l">
<div className="flag-wrapper">
       <span className="flag"> <Link to="/AddAudio" className="base-btn1"> Add Audio Book </Link></span>
</div>
</div>
</li>
</ul>
</div>
                     <div className="col-md-5" style={{padding:0}}>
                     <div className="zzz2">
                     {invalidImage !== null ? <p className="error" style={{float:"left",textAlign: "left"}}>{invalidImage}</p> : null }
                            {userlist.userimg.length > 0 && isuploadloader === false ? <> 
                            {userlist.userimg.map((item, index) => (
              <div key={item.register_id} className="profilepageleftsss">
                     <div className="profilepageleftsss2"> 
                     {item.profile_image && userInfo.filetemp === '' ?
                              <>
                     <img src={BaseURLuploads+item.profile_image} alt="userprofile" className="profileimgsss" />
                     <button onClick={editUploadMode} className="btn btn-primary4 zzz"> <FontAwesomeIcon icon={['fas','images']} className="iconsss2 " /> Change </button>
                     
                     {/* <h2> {item.name} </h2> */}
                     </>
                    : 
                    <>
                      {userInfo.filetemp ?
                        <div >
                             <img className="profileimgsss"  src={userInfo.filetemp} alt="UploadImage"/>
                            <button onClick={resetFile} className="btn btn-primary4 zzz"> <FontAwesomeIcon icon={['fas','trash-alt']} className="iconsss2" />  Remove</button>
                        </div>
                       :
                       <>
                        <img src={userphotos} alt="userprofile" className="profileimgsss"/>
                        <button onClick={editUploadMode} className="btn btn-primary4">  <FontAwesomeIcon icon={['fas','images']} className="iconsss2" /> Add Photo  </button>
                        </>
                        }
                   
                    
                    </>
                     }


                  </div>
               </div>

                   ))}



                     {isupload === true ?
                            <>

                             <form onSubmit={userphotosAdd} className="central-meta2">
                                   <div className="form-group2 ">
                                   <input type="file" name="file" onChange={handleChange}  className="form-control" placeholder=""   required/>
                                   </div>
                      
                      {/* Loading */}
                      {loadinglogin ?
                           <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                         : 
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="btn btn-primary"> <FontAwesomeIcon icon={['fas','save']} className="iconsss2sssq" /> Save </button>
                            </div>
                      } 

                      </form>
                      </> : null }
              </>
              :
              <ContentLoader height={200} width={326} speed={2} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                    <rect x="0" y="0" rx="0" ry="0" width="167" height="167"/> 
                    <rect x="207" y="47" rx="0" ry="0" width="137" height="47"/> 
                    <rect x="10" y="237" rx="0" ry="0" width="183" height="27"/> 
                     </ContentLoader> 
            }



</div>  
              </div>  

       

<div className="clearfix"></div>
<div className="col-md-12 eee">
{isbook.length > 0 ? <>
       <h2> Books </h2>
       {isbook.map((item, index) => ( 
              <div key={item.books_id} className="col-md-4" >
                            <Link to={`/Bookpreview/${item.books_id}`}>
                                  
                                                 <img src={BaseURLuploads + item.imagefront} alt="books" />
                                        
                            </Link>
              </div> 
       )) } </> 
: null}
</div>
<div className="col-md-12 eee2">
       {isaudio.length > 0 ? <>
       <h2> Audio Books </h2>
       {isaudio.map((item, index) => ( 
              <div key={item.audio_id} className="col-md-4" >
                            <Link to={`/AudioBookPreview/${item.audio_id}`}>
                                   <img src={BaseURLuploads + item.imagefront} alt="books" />
                                           
                            </Link>
              </div> 
       )) } </> 
: null}


</div>
<div className="col-md-12 eee">
       {isvideo.length > 0 ? <>
       <h2> Book Trailers </h2>
       {isvideo.map((item, index) => ( 
              <div key={item.video_id} className="col-md-6" >
                      {item.url.match(regExp) ? 
                                          <> 
                                                 {item.url.match(regExp).length >= 2 ? 

                                                 <iframe id="ytplayer" type="text/html" className="videosss2"
                                                 src={`https://www.youtube.com/embed/` + item.url.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                                                 frameBorder="0" allowFullScreen></iframe>
                                                 : null }
                                          </>
                                                 : null 
                                          } 
              </div> 
       )) } </> 
: null}


</div>
<div className="col-md-12 eee3">
       {ispublisher.length > 0 ? <>
       <h2> Publisher </h2>
       {ispublisher.map((item, index) => ( 
              <div key={item.publisher_id} className="col-md-4" >
                            <Link to={`/PublisherPreview/${item.publisher_id}`}>
                                                 <img src={BaseURLuploads + item.imagefront} alt="books" />
                                         
                            </Link>
              </div> 
       )) } </> 
: null}


</div>
             </>
              );
          }
   
export default DashboardAccount;
