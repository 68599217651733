import React, { Component } from 'react'

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import Pagination from 'react-bootstrap/Pagination'

import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;
class Highlighted extends Component {
  
  state = {
    classifieds: [],
    total: null,
    per_page: null,
    current_page: 1,
    isimpresivelatest:[]
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
    this.impressivelatest();
    // window.scrollTo(0, 0);
   }
   impressivelatest = async() =>{
     try {
       const response = await fetch(BaseURL+`viewall/impressivelatestbookfetch`, {
         method: 'GET',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
         },
       });
       const reslatest = await response.json();
     this.setState({
       isimpresivelatest: reslatest.bookslatestimpressive,
     });
           
     } catch (error) { throw error;}
 }


  makeHttpRequestWithPage = async pageNumber => {
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const response = await fetch(BaseURL+`viewall/bestdeal/${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    console.log(res);
    this.setState({
       classifieds: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }

  render() {
    const loaderplatinum = [1,2,3,4,5,6,7,8];
    let  allUsers,mainData, renderPageNumbers;
    if (this.state.classifieds !== null) {
      allUsers = this.state.classifieds.map(ads => (
              <div className="col-md-4 " key={ads.books_id}>
              <div className="news-block-one mmm ttt">
              <div className="inner-box">
              <div className="col-md-12" style={{padding:0}}>
                    <section className="app " id="app" data-current-media="book">
                   
                       <article className="media-container">
                     
                       <Link to={`/Bookpreview/${ads.books_id}`}>
                          <div className="book-wrapper">
                            <div className="book">
                              <div className="book__front">
                                <img src={BaseURLuploads+ads.imagefront} alt="books" />
                              </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                            </div>
                            <div className="book-shadow"></div>
                          </div>
                          
                          </Link>
                        </article>
                    </section>
                    </div> 

                    <div className="col-md-12" style={{padding:0}}>
                    <div className="ttt2" >
                     <div className="lower-content-homes">
                       <h6>{ads.category === 'other' ?
                                   <>{ads.other}</>
                                   :  <>{ads.category}  </>}</h6>
                     <h3> {ads.title} </h3>
                    
                    
                     </div>
                     <div className="desc2mmm" >
                      <div dangerouslySetInnerHTML={{ __html: ads.description }} />
                    </div> 
                     <div className="about-links">
                     <Link to={`/Bookpreview/${ads.books_id}`} className="base-btn1"> View Book </Link>
			</div>
                     </div>
                     </div>
              </div>
              </div>
              </div>  
       
      ));
      if( this.state.classifieds.length > 0){
        mainData = ( <div>{allUsers}  </div> );
    } else{
      mainData = ( <div>
                      {loaderplatinum.map((index) => (
                        <div key={ index } className="col-md-6" >
                        <ContentLoader height={337} width={500}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                          <rect x="10" y="10" rx="0" ry="0" width="183" height="277"/> 
                          <rect x="227" y="17" rx="3" ry="3" width="160" height="11"/> 
                          <rect x="227" y="47" rx="3" ry="3" width="160" height="20"/> 
                          <rect x="227" y="90" rx="3" ry="3" width="47" height="7"/> 
                          <rect x="287" y="90" rx="3" ry="3" width="160" height="7"/> 
                          <rect x="227" y="107" rx="3" ry="3" width="67" height="7"/> 
                          <rect x="327" y="107" rx="3" ry="3" width="127" height="7"/> 
                          <rect x="227" y="127" rx="3" ry="3" width="167" height="7"/> 
                          
                          <rect x="227" y="177" rx="3" ry="3" width="127" height="7"/> 
                          <rect x="367" y="177" rx="3" ry="3" width="67" height="7"/> 
                          <rect x="227" y="197" rx="3" ry="3" width="67" height="7"/> 
                          <rect x="307" y="197" rx="3" ry="3" width="127" height="7"/> 
                          <rect x="227" y="217" rx="3" ry="3" width="127" height="7"/> 
                          
                          <rect x="227" y="247" rx="3" ry="3" width="67" height="17"/> 

                        </ContentLoader> 
                        </div>
                      ))}
                    </div> )
    }
    }else{
      return (
        <div className="" style={{padding:'60px'}}>
        <div className="container">
           <div className="row">
           <div className="col-md-12">
        <div className="alert alert-light" role="alert">
        <h4 className="alert-heading"> Please Wait </h4>
        <hr/>
        {loaderplatinum.map((index) => (
                        <div key={ index } className="col-md-6" >
                        <ContentLoader height={337} width={500}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                          <rect x="10" y="10" rx="0" ry="0" width="183" height="277"/> 
                          <rect x="227" y="17" rx="3" ry="3" width="260" height="11"/> 
                          <rect x="227" y="47" rx="3" ry="3" width="260" height="20"/> 
                          <rect x="227" y="90" rx="3" ry="3" width="47" height="7"/> 
                          <rect x="287" y="90" rx="3" ry="3" width="190" height="7"/> 
                          <rect x="227" y="107" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="107" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="227" y="127" rx="3" ry="3" width="177" height="7"/> 
                          
                          <rect x="227" y="177" rx="3" ry="3" width="147" height="7"/> 
                          <rect x="387" y="177" rx="3" ry="3" width="77" height="7"/> 
                          <rect x="227" y="197" rx="3" ry="3" width="87" height="7"/> 
                          <rect x="327" y="197" rx="3" ry="3" width="137" height="7"/> 
                          <rect x="227" y="217" rx="3" ry="3" width="167" height="7"/> 
                          
                          <rect x="227" y="247" rx="3" ry="3" width="97" height="17"/> 

                        </ContentLoader> 
                        </div>
                      ))}
    </div>
    </div>
    </div>
    </div>
    </div>
      )
     }
    
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }


      renderPageNumbers = pageNumbers.map(number => {
        //console.log('current_page: '+this.state.page+' number : '+number);
        let classes = this.state.current_page != number ? '' : 'active';

        return (
             
               <Pagination.Item key={number} className={classes}  onClick={() => this.makeHttpRequestWithPage(number)}>
               {number}
             </Pagination.Item>
       //    <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
        );
      });
    }



    return (
      <>
      
       <div className="ccc pagebooks">
       <div className="container">
          <div className="row">
          <div className="col-md-12">
          <div className="pagebooks2">
           
           
            <div className="col-md-9 yyy">
            <h2> <span className="cols"> Highlighted </span> Books </h2>
          {mainData}
          </div>
          <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {this.state.isimpresivelatest.length > 0 ? <>
                   
                     {this.state.isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                             <div className="aaa4">
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                                   </div>
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 
          <div className="col-md-12">
                 
      <div className="pagination">
        <span onClick={() => this.makeHttpRequestWithPage(1)}><Pagination.First /></span>
        {renderPageNumbers}
        <span onClick={() => this.makeHttpRequestWithPage(this.state.total)}><Pagination.Last /></span>
      </div>
      </div>
      </div>
      </div>

   
    </div>
    </div>
    </div>
    </>
  );
}

}

export default Highlighted
