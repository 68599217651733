
import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';

//include page
import Loginpage from './Loginpage';
import Forgetpassword from './Forgetpassword';
import Registerpage from './Registerpage';

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Loginsection() {
  const {impressivelatestbook} = useContext(MyContext);//const {} = useContext(MyContext);
  const [isimpresivelatest, setimpresivelatest] = useState([]);
  

  useEffect( () => {
    //storedata(); 
    impressivelatest();
    window.scrollTo(0, 0);
  }, []);


  const impressivelatest = async() =>{
    try {
           const impressiveresult = await impressivelatestbook();
                  if(impressiveresult.success === 1){
                         console.log(impressiveresult.bookslatestimpressive);
                         setimpresivelatest(impressiveresult.bookslatestimpressive) ;
           }
    } catch (error) { throw error;}
}


   const [isLogin, setisLogin] = useState(false);
   const [isRegister, setisRegister] = useState(false);
   const [isForget, setisForget] = useState(false);

   const RegisterMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(true);
       setisRegister(true);
       setisForget(false);
      }
    
  const LoginMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(false);
       setisRegister(false);
       setisForget(false);
    
  
  }
  const ForgetMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(true);
       setisRegister(false);
       setisForget(true);
  }  



        return(
            <>
            
            <div className="clearfix"></div>
               <div className="login-1">
                  <div className="container">
                     <div className="row">
                     
                       <div className="col-md-9">
                       <div className="logsss3">
                      

                          {/* Login Mode */}
                          {isLogin !== true ?
                              <>
                              <Loginpage />
                                <h6 onClick={ForgetMode}> Forgot Password </h6>
                                <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#0ca9f3'}}> Register </span>  </h6>
                             </>
                            : null }

                            {/* Register Mode */}
                              {isRegister === true ?
                                <>
                                <Registerpage LoginMode ={LoginMode}/>
                                  <h6 onClick={LoginMode}> Already have an Account ? <span style={{color:'#0ca9f3'}}> Login here </span></h6>
                                  </>
                              : null }

                              {/* Forget Password */}
                              {isForget === true ? 
                              <>
                              <Forgetpassword  />  
                              <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#0ca9f3'}}> Register </span>  </h6>
                              </>
                              : null  }



                       </div>
                       </div>
                       <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                   
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 
                     </div>
                  </div>
            </div>
            </>
        );
    }
export default Loginsection;