import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublisherEdit from './PublisherEdit';

import userphotos from '../Img/user_profile.jpg';

import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";


import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;



function PublisherPreview(props) {

    const {Publisherpreview,rootState,impressivelatestbook} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [isEditingplan, setisEditingplan] = useState(false);
    const [isimpresivelatest, setimpresivelatest] = useState([]);
    const loaderplatinum = [1,2,3,4,5,6,7,8];

    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
       impressivelatest();
      }, []); 

      const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
   }
     
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
                     const dataaddpreview = await Publisherpreview(ids);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview);
                            setisbookpreview(dataaddpreview.bookpreviewsall);
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              setisbookpreview([]);
              booklistpreview(props.match.params.Addpreview);
       }
    
      const toggle = () => {
               setaddClass({addClass: !addClass});
            }
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }


       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  

   
        return(
       <div className="bookadspreview">
              
              <div className="container">
                     <div className="row">
                            
                     {isbookpreview.length !== 0 ? <>
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.publisher_id} className="col-md-9" style={{padding:0}}> 
                          
                     {isEditing === true  ?
                             <PublisherEdit publisher_id={props.match.params.Addpreview} title={item.title} email={item.email} phone={item.phone} description={item.description} websitelink={item.websitelink}  editModepreviewchilds={editModepreviewchildedit } />

                             : 
                             <>
                            <div className="col-md-12 yyy">
                            {theUser === item.user_id || theUser === 2 || theUser === 6 ? <>  
                                   <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                                  
                             </>: null}
                          
                             {theUser === 2  || theUser === 6 ? <> 
                                   <Link to={`/PdfPreviewpublisher/${item.publisher_id}`} style={{float:"right"}}>  <button className="thm-btn pricing-one__btn editsss" > Payment Receipt <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                                   </Link>
                          </>: null}

                     <div className="clearfix"></div>

                    
                            <div className="col-md-12" style={{padding:0}}>
                            
                    
                    






                            <div className="col-md-4 leqssswww">
                            <img src={BaseURLuploads+item.imagefront}  alt="" />
                            
                            </div>
                            <div className="col-md-8 " style={{padding:0}}>
                         
                            <div className="zzz7">
                       
                                   
                            <h1>{item.title}</h1>
                            <div className="dythjkyuf">
                            {item.profile_image !== '' ?
                            <>
                                   <img src={BaseURLuploads+item.profile_image} alt="userprofile" className="avaterimggg" />
                            </>
                     : 
                     
                            <>
                                   <img src={userphotos} alt="userprofile" className="avaterimggg"/>
                            </>
                            }
                
                 <h2 className="usernamesss">{item.name}</h2>
                 </div>
                                   <h3> <FontAwesomeIcon icon={['fas','envelope']} className="iconsss2" /> Email : <span style={{color:"#ff4242"}} > {item.email}</span></h3>
                                   <hr />
                                   <h3> <FontAwesomeIcon icon={['fas','phone-alt']} className="iconsss2" />  Phone  : <span style={{color:"#ff4242"}} > {item.phone }</span></h3>
                                   <hr />
                                   
                                   <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />  Website Link  : <p onClick={() => externallinks(item.websitelink)}>{item.websitelink} </p> </h3>

                                   </div>
                                   
                                   
                                                 </div>
                                          
                                   
                                   </div>
                                   <div className="col-md-12" style={{backgroundColor: '#ffffffb3'}}>
                                   

                                   <div  className={boxClass.join(' ')}>
                                          {/* {descriptionviews} */}
                                   <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                          </div>

                                          {addClass ? 
                                          <p className="redmoesssmmm" onClick={toggle}>  </p>
                                          :
                                          <p className="redmoesssmmm" onClick={toggle}> More </p>
                                          }

                               </div>





                          </div>
            
                             
                            
                     
                     </>
                     }



                      </div>
                      ))}
                      </>
                      : 
                      <>
                      <div className="col-md-8 yyy">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                    
                      <rect x="0" y="107" rx="0" ry="0" width="800" height="477"/> 
                      
                      </ContentLoader>
             
                      </div> 
                      
                   </>
              }
             
             <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12 ">
                              <div className=" aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                             
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 




             </div>
             </div>
             </div>
        );
    }

export default PublisherPreview;
