import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "./PdfDocumentBook";


function PdfPreviewbook(props) {

    const {booksDetails} = useContext(MyContext);

    const [isBookDetails, setBookDetails] = useState([]);
    //const loaderplatinum = [1,2,3,4,5,6,7,8];
    
    useEffect( () => {
    RecepitBookDetails(props.match.params.Addpreview);
      }, []); 
   
      const RecepitBookDetails = async(bookids) =>{
       try {
              const booklist = await booksDetails(bookids);
              console.log(booklist);
                     if(booklist.success === 1){
                       console.log(booklist.bookdetailsall);
                       setBookDetails(booklist.bookdetailsall);
                      
             }
       } catch (error) { throw error;}
     }
      
        return(
               <>
               <div className="clearfix"></div>
       <div className="bookadspreview">
           
              <div className="container">
                     <div className="row">
                     <div className="col-md-4"> &nbsp; </div>
                     
                             <PDFDownloadLink
                                   document={<PdfDocument bookpdflist={isBookDetails} />}
                                   fileName="PaymentReceipt.pdf"
                                   style={{
                                   textDecoration: 'none',
                                   padding: '12px',
                                   color: 'rgb(255 255 255)',
                                   backgroundColor: 'rgb(251, 106, 31)',
                                   marginTop: '7px',
                                   fontSize: '20px',
                                   float: 'right',
                                   borderRadius: '7px',
                                   marginLeft: '17px',
                                   }}
                            >
                                   {({ blob, url, loading, error }) =>
                                   loading ? "Loading document..." : "Download Pdf"
                                   }
                            </PDFDownloadLink>

                          
             </div>
             </div>
             </div>
             </>
        );
    }

export default PdfPreviewbook;