import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import VideoEdit from './VideoEdit';
import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import { WEB_URL,WEB_URL_UPLOAD,API_KEY_STRIPE } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;
let baseURLpaypal= WEB_URL;

function AddBookTrailerpreview(props) {
       let history = useHistory();
    const {newvideoaddpreview,rootState,videobookstripe,impressivelatestbook} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [ispaymentprice, setpaymentprice] = useState(false);
    const [ispaymentbookid, setispaymentbookid] = useState(false);
    const [paynowpaypal, setpaynowpaypal] = useState(false);
    const [paynowstripe, setpaynowstripe] = useState(true);
    const [isimpresivelatest, setimpresivelatest] = useState([]);
    
    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
       impressivelatest();
      }, []); 
   

      const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult.bookslatestimpressive);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
   }
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
              const dataaddpreview = await newvideoaddpreview(ids);
              if(dataaddpreview.success === 1){
                     console.log(dataaddpreview.bookpreviewsall);
                     setisbookpreview(dataaddpreview.bookpreviewsall);
                     setpaymentprice(dataaddpreview.bookpreviewsall[0].paymentamount)
                     setispaymentbookid(dataaddpreview.bookpreviewsall[0].video_id)
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              setisbookpreview([]);
              booklistpreview(props.match.params.Addpreview);
       }
      
     


     
   const makePayment = async (token) => {
          //console.log(ispaymentprice);
       try {
              const datastripe = await videobookstripe(token,theUser,ispaymentbookid,ispaymentprice);
              console.log(datastripe);
              if(datastripe.success === 1){

                     history.push(`/StripevidoSuccess/${datastripe.stripeid}`);

                  } 
       } catch (error) { throw error;}    
   }


   
   const paypalMode = () => {
       setpaynowpaypal(true);
       setpaynowstripe(false);
   }
  const stripeMode = () => {
       setpaynowpaypal(false);
       setpaynowstripe(true);
   }


   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;

   
        return(
       <div className="bookadspreview bookvissss">
              
              <div className="container">
                     <div className="row">
                   
                     {isbookpreview.length > 0 ? <>        
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.video_id} className="col-md-9" style={{padding:0,}}> 
                             {theUser === item.user_id ? <>  
                     {isEditing === true  ?
                             <VideoEdit video_id={props.match.params.Addpreview} title={item.title} url={item.url} editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            
                               
                            <div className="col-md-12 bookadspreviewlsss">
                               
                          <h1> Onetime Fee : ${item.paymentamount}</h1>
                          
                     <hr/>
                            <h3> Book Trailer </h3>
                         



                            

                          <div className="clearfix masssqqq"></div>
                            <h1>Choose your payment method</h1>
                            <hr/>
                          <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                  
                            <p className="pymt-type-name"> <span className="stripsss"> Stripe </span></p>
                          
                     </div>
                    
                     
                     </div>

                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                    
                            <p className="pymt-type-name"><span className="Pay">Pay</span><span className="pal">pal</span></p>
                           
                     </div>
                    
                     
                     </div>
                     <hr/>
 




                          <div className="clearfix"></div>
              {paynowstripe === true ? 
                          <StripeCheckout stripeKey={API_KEY_STRIPE} token={makePayment} name="Book Trailers" description=" Onetime Fee" amount={item.paymentamount * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="thm-btn pricing-one__btn"> Proceed to checkout <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /> </div>
                     </StripeCheckout>
              : 

                     <a href={baseURLpaypal+`paypalvideo/pay/`+theUser+`/`+item.video_id+`/`+item.paymentamount}  className="thm-btn pricing-one__btn" > Proceed to checkout <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /></a>


              }


                     
                            </div>

                            <div className="col-md-12 yyy">

                            <button className="thm-btn pricing-one__btn editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                            

                          
                     <div className="clearfix"></div>

                     <h1>{item.title}</h1>
                            <div className="col-md-12 zzz8">

       {item.url.match(regExp) ? <> 
            {item.url.match(regExp).length >= 2 ? <>      
                            <iframe id="ytplayer" type="text/html" className="videosss"
                         src={`https://www.youtube.com/embed/`+item.url.match(regExp)[2]+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 

                          </>:null}
                          </>:null}
                          
                          </div>





                          </div>
                         
                             
                            
                      </>
                     }
                    


</>: null}
                      </div>
                      ))}
                       </>
                      : 
                      <>     <div className="col-md-8 yyy">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                      
                     <rect x="0" y="107" rx="0" ry="0" width="800" height="477"/> 
                      
                      </ContentLoader>
             
                      </div> 
                      
                      
                   </>
              }














<div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                   
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 




             </div>
             
             </div>
             </div>
        );
    }

export default AddBookTrailerpreview;