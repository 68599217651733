
import React,{useState} from 'react';
import { Page, Text, View, Document, StyleSheet,Image} from "@react-pdf/renderer";


//import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
import logo from '../Img/logo.jpg';

function PdfDocumentpublisher(props) {

  console.log("pdf props", props.bookpdflist);
  return (
    <Document>
      <Page style={styles.page} >


        {props.bookpdflist.map((item, index) => {
              return (
                <View key={index} style={styles.movieContainer}>
                
                  <Image object-fit="fill" src={logo} style={styles.image} alt="images" />

                  <Text style={styles.movieTitle2}> Publisher: </Text>
                  <View style={styles.movieDetails}>
                    
                    <Text style={styles.movieTitle}>Publisher Title : {item.title}</Text>
                    <Text style={styles.movieTitle}>Publisher Email  : {item.email}</Text>
                    
                </View>

                  <Text style={styles.movieTitle2}> YOUR ORDER INFORMATION: </Text>
                  <View style={styles.movieDetails}>
                    
                    <Text style={styles.movieTitle}>Bill To : {item.email}</Text>
                    <Text style={styles.movieTitle}>Order ID : #bd3f8422a17f-{item.member_id}</Text>
                    <Text style={styles.movieTitle}>Payment Plan : {item.paymentbook}</Text>
                    <Text style={styles.movieTitle}>Amount : <Text style={styles.movieTitle3}>${item.paymentamount}</Text></Text>
                    {item.reportpay === 'paypal' ?
                    <Text style={styles.movieTitle}>Paypal transaction id : {item.txn_id}</Text>
                    : 
                    <Text style={styles.movieTitle}>Stripe transaction id : {item.txn_id}</Text>} 
                   
                </View>
                <Text style={styles.movieTitle32s}>Thank you for posting your publisher profile on <Text style={styles.movieTitle3s}>Writers Support.</Text></Text>
                </View>
              );
            })}
      </Page>
    </Document>
  );
}
export default PdfDocumentpublisher;


const styles = StyleSheet.create({
      page: {
         backgroundColor: "#ffffff"
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 1
       },
       movieContainer: {
         display: "flex",
         flexDirection: "column",
         padding: 37
       },
       movieDetails: {
        display: "flex",
        marginLeft: 5,
        borderWidth: 4,
        borderColor: "#20232a",
        borderRadius: 6,
        padding: 27,
        marginBottom:17
       },
       movieTitle: {
         fontSize: 16,
         marginTop: 10,
         marginBottom: 10,
         fontWeight:700,
       },
       movieTitle2: {
         fontSize: 22,
         marginBottom: 17,
         color: '#fb6a1f',
         fontWeight: "bold"
       },
       movieTitle3: {
         fontSize: 20,
         marginBottom: 27,
         color: '#fb6a1f',
         fontWeight:700
       },
       movieTitle32s: {
        fontSize: 16,
      },
        movieTitle3s: {
         fontSize: 16,
         color: '#fb6a1f',
       },
       movieOverview: {
         fontSize: 10
       },
       movieTitle7s: {
        color: '#2196f3',
       },
     
       image: {
        alignItems: "center",
        justifyContent: "center",
        display: "block",
        flexDirection: "column",
        maxWidth:200,
        marginBottom:37
       },
       subtitle: {
         display: "flex",
         justifyContent: "space-between",
         flexDirection: "row",
         width: 150,
         alignItems: "center",
         marginBottom: 12
       },
       vote: {
         display: "flex",
         flexDirection: "row"
       },
       rating: {
         height: 10,
         width: 10
       },
       vote_text: {
         fontSize: 10
       },
       vote_pop: {
         fontSize: 10,
         padding: 2,
         backgroundColor: "#61C74F",
         color: "#fff"
       },
       vote_pop_text: {
         fontSize: 10,
         marginLeft: 4
       },
       overviewContainer: {
         minHeight: 110
       },
       detailsFooter: {
         display: "flex",
         flexDirection: "row"
       },
       lang: {
         fontSize: 8,
         fontWeight: 700
       },
       vote_average: {
         fontSize: 8,
         marginLeft: 4,
         fontWeight: "bold"
       }
     });


