
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";

import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Cancel(props) {


  const {impressivelatestbook} = useContext(MyContext);
  const [isimpresivelatest, setimpresivelatest] = useState([]);
  
  useEffect( () => {
     window.scrollTo(0, 0);
     impressivelatest();
 }, []);


 const impressivelatest = async() =>{
  try {
         const impressiveresult = await impressivelatestbook();
                if(impressiveresult.success === 1){
                       console.log(impressiveresult.bookslatestimpressive);
                       setimpresivelatest(impressiveresult.bookslatestimpressive) ;
         }
  } catch (error) { throw error;}
}



    return (
      <>
       <div className="clearfix"></div>
       <div className="successs">
        <div className="container">
          <div className="row">
            
         
              <div className="col-md-9 ooo2">

              <div className="col-md-12" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> PayPal Transaction </h1>
                </div>
              </div>
            </div>


        
         
              <h2>Your PayPal Transaction Canceled.</h2>
          
           
                          <Link to={`/Myaccount`} className="thm-btn pricing-one__btn" > Payment Again </Link>
                      
                                
                   
                   
              </div>


              <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                     
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 

                         

       </div>
  </div>
</div>
      </>
    );
  }


export default Cancel;