
import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
  

    state = {
        isAuth:false,
        theUser:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
    
        isLoggedIn = async () => {
            let userstorageid = JSON.parse(localStorage.getItem('loginToken'));
            if (localStorage.getItem('loginToken')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUser:userstorageid
                });
                //console.log(this.state.isAuth);
            } 
    
        }
         
         logoutUser = () => {
            localStorage.removeItem('loginToken');
            this.setState({
                ...this.state,
                isAuth:false
            })
            
            window.location.href = '/Login';
        }
    
        RegisterUser = async (user,recaptcha_response) => {
            const registerqqq = await Axios.post('login/Register',{
                user_name:user.name,
                user_email:user.email,
                password:user.password,
                recaptcha_response:recaptcha_response,
            });
            return registerqqq.data;
        }

loginUser = async (user) => {
    const login = await Axios.post('login/userlogin',{
        email:user.email,
        password:user.password
    });
    return login.data;
}


impresivebooks = async () => {
    const impresivebooksqqq = await Axios.get('viewall/impresivebooksfetch');
    return impresivebooksqqq.data;
}
highlightedbooks = async () => {
    const highlightedbooksqqq = await Axios.get('viewall/highlightedbooksfetch');
    return highlightedbooksqqq.data;
}
slidertrailers = async () => {
    const trailersone = await Axios.get('viewall/trailerfetchonetwo');
    return trailersone.data;
}
slidefeatured = async () => {
    const featuredsone = await Axios.get('viewall/featuredfetchone');
    return featuredsone.data;
}
sliderstandard = async () => {
    const featuredsone = await Axios.get('viewall/standardfetchone');
    return featuredsone.data;
}
impressivelatestbook = async () => {
    const impressivebooksone = await Axios.get('viewall/impressivelatestbookfetch');
    return impressivebooksone.data;
} 
    newbookadd = async (book,desc,userids,plan,paymentbook) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('price',book.price);
        formData.append('purchase',book.purchase);
        formData.append('website',book.website);
        formData.append('category',book.category);
        formData.append('descothers',book.descothers);
        formData.append('userids',userids);
        formData.append('plan',plan);
        formData.append('paymentbook',paymentbook);
        const booksaddsss = await Axios.post('add/bookadd',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return booksaddsss.data;
    }
    newbookupdate = async (book,desc,booksid,userids,fileempty,plan,paymentbook) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('price',book.price);
        formData.append('purchase',book.purchase);
        formData.append('website',book.website);
        formData.append('category',book.category);
        formData.append('descothers',book.descothers);
        formData.append('booksids',booksid);
        formData.append('fileempty',fileempty);
        formData.append('userids',userids);
        formData.append('plan',plan);
        formData.append('paymentbook',paymentbook);
        const booksaddsss = await Axios.post('update/bookaddupdate',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return booksaddsss.data;
    }
    pricelistall = async () => {
        const pricelistallqqq = await Axios.get('viewall/price');
        return pricelistallqqq.data;
    }
    newbookaddpreview = async (ids) => {
        const newbookaddpreviewsss = await Axios.post('viewall/Addbookpreview',{
            bookids:ids,
        });
        return newbookaddpreviewsss.data;
    } 
    bookstripe = async (token,theUser,theUserbookid,ispaymentprice) => {
        const bookstripeqqq = await Axios.post('stripebook/bookstripepayment',{
            token:token,
            theUser:theUser,
            theUserbookid:theUserbookid,
            ispaymentprice:ispaymentprice,
        });
        return bookstripeqqq.data;
    }
    newAudiobookadd = async (book,desc,userids,amount) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('written',book.written);
        formData.append('narrated',book.narrated);
        formData.append('hrs',book.hrs);
        formData.append('mins',book.mins);
        formData.append('price',book.price);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('purchase',book.purchase);
        formData.append('website',book.website);
        formData.append('userids',userids);
        formData.append('amount',amount);
        const booksaddsss = await Axios.post('add/audioadd',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return booksaddsss.data;
    }
    newAudiobookaddpreview = async (ids) => {
        const newAudiobookaddpreviewsss = await Axios.post('viewall/AddAudiobookpreview',{
            bookids:ids,
        });
        return newAudiobookaddpreviewsss.data;
    }
    newAudiobookEdit = async (book,desc,userids,audioid,fileempty) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('written',book.written);
        formData.append('narrated',book.narrated);
        formData.append('hrs',book.hrs);
        formData.append('mins',book.mins);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('price',book.price);
        formData.append('purchase',book.purchase);
        formData.append('website',book.website);
        formData.append('userids',userids);
        formData.append('audioid',audioid);
        formData.append('fileempty',fileempty);
        const booksaddsssedit = await Axios.post('update/audioedit',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return booksaddsssedit.data;
    }
    Audiobookstripe = async (token,theUser,theUserbookid,ispaymentprice) => {
        const Audiobookstripeqqq = await Axios.post('stripebookaudio/bookstripepayment',{
            token:token,
            theUser:theUser,
            theUserbookid:theUserbookid,
            ispaymentprice:ispaymentprice,
        });
        return Audiobookstripeqqq.data;
    }
    newbooktrailersadd = async (book,userids,amount) => {
        const newbooktrailersaddsss = await Axios.post('add/booktrailersadd',{
            title:book.title,
            url:book.url,
            userids:userids,
            amount:amount,
        });
        return newbooktrailersaddsss.data;
    }  
    newvideoaddpreview = async (ids) => {
        const newvideoaddpreviewsss = await Axios.post('viewall/Addvideopreview',{
            bookids:ids,
        });
        return newvideoaddpreviewsss.data;
    }  
    newbooktrailersedits = async (book,video_id) => {
        const newbooktrailerseditsqqq = await Axios.post('update/booktrailersedit',{
            title:book.title,
            url:book.url,
            video_id:video_id,
        });
        return newbooktrailerseditsqqq.data;
    }  
    videobookstripe = async (token,theUser,theUserbookid,ispaymentprice) => {
        const videobookstripeqqq = await Axios.post('stripebookvideo/bookstripepayment',{
            token:token,
            theUser:theUser,
            theUserbookid:theUserbookid,
            ispaymentprice:ispaymentprice,
        });
        return videobookstripeqqq.data;
    }
    newpublishersadd = async (book,desc,userids,amount) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('email',book.email);
        formData.append('phone',book.phone);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('website',book.website);
        formData.append('userids',userids);
        formData.append('amount',amount);
        const publishersss = await Axios.post('add/addpublishers',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return publishersss.data;
    }   
    newpublisheraddpreview = async (ids) => {
        const newpublisheraddpreviewqqq = await Axios.post('viewall/Addpublisherspreview',{
            bookids:ids,
        });
        return newpublisheraddpreviewqqq.data;
    }
    newpublishersupdate = async (book,desc,userids,publisher_id,fileempty) => {
        const formData = new FormData();
        formData.append('title',book.title);
        formData.append('email',book.email);
        formData.append('phone',book.phone);
        formData.append('description',desc);
        formData.append('avatar',book.file);
        formData.append('website',book.website);
        formData.append('userids',userids);
        formData.append('publisher_id',publisher_id);
        formData.append('fileempty',fileempty);
        const newpublishersupdatesss = await Axios.post('update/publisheredit',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return newpublishersupdatesss.data;
    }    
    Publisherbookstripe = async (token,theUser,theUserbookid,ispaymentprice) => {
        const Publisherbookstripeqqqq = await Axios.post('stripebookpublisher/bookstripepayment',{
            token:token,
            theUser:theUser,
            theUserbookid:theUserbookid,
            ispaymentprice:ispaymentprice,
        });
        return Publisherbookstripeqqqq.data;
    }       
    paypalsuccessbook = async (ids) => {
        const paypalsuccessbookqqq = await Axios.post('viewall/paypalbook',{
            bookids:ids,
        });
        return paypalsuccessbookqqq.data;
    }
    paypalsuccessPublisher = async (ids) => {
        const paypalsuccessPublishersss = await Axios.post('viewall/paypalPublisher',{
            bookids:ids,
        });
        return paypalsuccessPublishersss.data;
    }
    paypalsuccessvideo = async (ids) => {
        const paypalsuccessvideoqqq = await Axios.post('viewall/paypalvido',{
            bookids:ids,
        });
        return paypalsuccessvideoqqq.data;
    }
    paypalsuccessAudio = async (ids) => {
        const paypalsuccessAudioqqq = await Axios.post('viewall/paypalaudio',{
            bookids:ids,
        });
        return paypalsuccessAudioqqq.data;
    }
    stripesuccessbook = async (ids) => {
        const stripesuccessbooksss = await Axios.post('viewall/stripebook',{
            bookids:ids,
        });
        return stripesuccessbooksss.data;
    }
    stripesuccessPublisher = async (ids) => {
        const stripesuccessPublishersss = await Axios.post('viewall/stripePublisher',{
            bookids:ids,
        });
        return stripesuccessPublishersss.data;
    }
    stripesuccessvido = async (ids) => {
        const stripesuccessvidosss = await Axios.post('viewall/stripevido',{
            bookids:ids,
        });
        return stripesuccessvidosss.data;
    }
    stripesuccessAudio = async (ids) => {
        const stripesuccessAudiosss = await Axios.post('viewall/stripeaudio',{
            bookids:ids,
        });
        return stripesuccessAudiosss.data;
    }
    upgradeplanupdate = async (ids,plan,price) => {
        
        const Addplanupdateqqq = await Axios.post('update/updateplanupgrade',{
            bookids:ids,
            plan:plan,
            price:price,
        });
        return Addplanupdateqqq.data;
    }
    bookstripeupgrade = async (token,theUser,theUserbookid,ispaymentprice) => {
        const bookstripeqqq = await Axios.post('stripebookupgrade/bookstripepayment',{
            token:token,
            theUser:theUser,
            theUserbookid:theUserbookid,
            ispaymentprice:ispaymentprice,
        });
        return bookstripeqqq.data;
    }
    Audiopreview = async (ids) => {
        const Audiopreviewqqq = await Axios.post('viewall/Audiopreviewsss',{
            bookids:ids,
        });
        return Audiopreviewqqq.data;
    }
    Publisherpreview = async (ids) => {
        const Publisherpreviewqqq = await Axios.post('viewall/Publisherpreviewsss',{
            bookids:ids,
        });
        return Publisherpreviewqqq.data;
    }
    loginUserimage = async (file,ids) => {
        const formData = new FormData();
        formData.append('avatar',file);
        formData.append('storageid',ids);
        const userimg = await Axios.post('update/profileupdate',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return userimg.data;
    }
    viewuserprofile = async (userid) => {
        const viewuserprofileqqq = await Axios.post('viewall/register',{
            registerid:userid,
        });
        return viewuserprofileqqq.data;
    }
    mybookview = async (ids) => {
        const mybookviewqqq = await Axios.post('viewall/mybookviewfetch',{
            ids:ids,
        });
        return mybookviewqqq.data;
    }
    myaudioview = async (ids) => {
        const myaudioviewqqq = await Axios.post('viewall/myaudioviewfetch',{
            ids:ids,
        });
        return myaudioviewqqq.data;
    } 
    myvideoview = async (ids) => {
        const myvideoviewqqq = await Axios.post('viewall/myvideoviewfetch',{
            ids:ids,
        });
        return myvideoviewqqq.data;
    }
    mypublisherview = async (ids) => {
        const mypublisherviewqqq = await Axios.post('viewall/mypublisherviewfetch',{
            ids:ids,
        });
        return mypublisherviewqqq.data;
    }
    loginUserupdate = async (userprofileid,ids) => {
        const loginUserupdateqqq = await Axios.post('login/changepassword',{
            id:ids,
            name:userprofileid.name,
            newpassword:userprofileid.password,
        });
        return loginUserupdateqqq.data;
    }
    paypendingbooks = async (theUserid) => {
        const paypendingbooksqqq = await Axios.post('viewall/paypendingbooksall',{
            theUser:theUserid,
        });
        return paypendingbooksqqq.data;
    }
    paypendingaudio = async (theUserid) => {
        const paypendingaudioqqq = await Axios.post('viewall/paypendingaudioall',{
            theUser:theUserid,
        });
        return paypendingaudioqqq.data;
    }
    paypendingvideo = async (theUserid) => {
        const paypendingvideoqqq = await Axios.post('viewall/paypendingvideoall',{
            theUser:theUserid,
        });
        return paypendingvideoqqq.data;
    }
    paypendingpublisher = async (theUserid) => {
        const paypendingpublisherqqq = await Axios.post('viewall/paypendingpublisherall',{
            theUser:theUserid,
        });
        return paypendingpublisherqqq.data;
    }
    booksrecepit = async (userid) => {
        const booksrecepitqqq = await Axios.post('viewall/booksrecepitfetch',{
            theUser:userid,
        });
        return booksrecepitqqq.data;
    }
    audiobooksrecepit = async (userid) => {
        const audiobooksrecepitqqq = await Axios.post('viewall/audiorecepitfetch',{
            theUser:userid,
        });
        return audiobooksrecepitqqq.data;
    }
    videobooksrecepit = async (userid) => {
        const videorecepitqqq = await Axios.post('viewall/videorecepitfetch',{
            theUser:userid,
        });
        return videorecepitqqq.data;
    }
    publisherbooksrecepit = async (userid) => {
        const publisherrecepitqqq = await Axios.post('viewall/publisherrecepitfetch',{
            theUser:userid,
        });
        return  publisherrecepitqqq.data;
    }

    booksDetails = async (bookids) => {
        const booksDetailswww = await Axios.post('viewall/booksDetailsfetch',{
            bookids:bookids,
        });
        return booksDetailswww.data;
    }
    audiobooksDetails = async (bookids) => {
        const audiobooksDetailswww = await Axios.post('viewall/audiobooksDetailsfetch',{
            bookids:bookids,
        });
        return audiobooksDetailswww.data;
    }
    videobooksDetails = async (bookids) => {
        const videobooksDetailswww = await Axios.post('viewall/videobooksDetailsfetch',{
            bookids:bookids,
        });
        return videobooksDetailswww.data;
    }
    publisherbooksDetails = async (bookids) => {
        const publisherbooksDetailswww = await Axios.post('viewall/publisherbooksDetailsfetch',{
            bookids:bookids,
        });
        return publisherbooksDetailswww.data;
    }
    sliderAudio = async () => {
        const Audiosone = await Axios.get('viewall/audiofetchone');
        return Audiosone.data;
    }
    sliderPublisherqqq = async () => {
        const Publisherqqq = await Axios.get('viewall/publisherfetchone');
        return Publisherqqq.data;
    }
    latestallbookslist = async () => {
        const latestallbookslistqqq = await Axios.get('viewall/latestallbookslistfetch');
        return latestallbookslistqqq.data;
    } 
    changeprice = async (price) => {
        const changepriceqqq = await Axios.post('update/changepriceplan',{
            Recommended:price.Recommended,
            Highlighted:price.Highlighted,
            Featured:price.Featured,
            Standard:price.Standard,
            Audio:price.Audio,
            Publisher:price.Publisher,
            BookTrailer:price.BookTrailer,
        });
        return changepriceqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('login/forgetpassword',{
            email:user.useremail,
        });
        return loginForget.data;
    }
    addnewsletters = async (user) => {
        const addnewsletterssss = await Axios.post('add/addnewsletters',{
            email:user.email,
        });
        return addnewsletterssss.data;
    }
    

    render(){
        const contextValue = {
            
            rootState:this.state,

            loginUser:this.loginUser,
            RegisterUser:this.RegisterUser,
            logoutUser:this.logoutUser,
            isLoggedIn:this.isLoggedIn,

            impresivebooks:this.impresivebooks,
            highlightedbooks:this.highlightedbooks,
            slidertrailers:this.slidertrailers,
            slidefeatured:this.slidefeatured,
            sliderstandard:this.sliderstandard,

            impressivelatestbook:this.impressivelatestbook,
            sliderAudio:this.sliderAudio,
            sliderPublisherqqq:this.sliderPublisherqqq,
            latestallbookslist:this.latestallbookslist,
            

            newbookadd:this.newbookadd,
            newbookupdate:this.newbookupdate,
            newbookaddpreview:this.newbookaddpreview,
            bookstripe:this.bookstripe,
            paypalsuccessbook:this.paypalsuccessbook,
            stripesuccessbook:this.stripesuccessbook,

            upgradeplanupdate:this.upgradeplanupdate,
            bookstripeupgrade:this.bookstripeupgrade,
            

            newAudiobookadd:this.newAudiobookadd,
            newAudiobookaddpreview:this.newAudiobookaddpreview,
            newAudiobookEdit:this.newAudiobookEdit,
            Audiobookstripe:this.Audiobookstripe,
            paypalsuccessAudio:this.paypalsuccessAudio,
            stripesuccessAudio:this.stripesuccessAudio,
            Audiopreview:this.Audiopreview,

           

            newbooktrailersadd:this.newbooktrailersadd,
            newvideoaddpreview:this.newvideoaddpreview,
            newbooktrailersedits:this.newbooktrailersedits,
            videobookstripe:this.videobookstripe,
            paypalsuccessvideo:this.paypalsuccessvideo,
            stripesuccessvido:this.stripesuccessvido,

            newpublishersadd:this.newpublishersadd,
            newpublisheraddpreview:this.newpublisheraddpreview,
            newpublishersupdate:this.newpublishersupdate,
            Publisherbookstripe:this.Publisherbookstripe,
            paypalsuccessPublisher:this.paypalsuccessPublisher,
            stripesuccessPublisher:this.stripesuccessPublisher,
            Publisherpreview:this.Publisherpreview,

            loginUserimage:this.loginUserimage,
            viewuserprofile:this.viewuserprofile,
            mybookview:this.mybookview,
            myaudioview:this.myaudioview,
            myvideoview:this.myvideoview,
            mypublisherview:this.mypublisherview,
            loginUserupdate:this.loginUserupdate,
            paypendingbooks:this.paypendingbooks,
            paypendingaudio:this.paypendingaudio,
            paypendingvideo:this.paypendingvideo,
            paypendingpublisher:this.paypendingpublisher,

            booksrecepit:this.booksrecepit,
            audiobooksrecepit:this.audiobooksrecepit,
            videobooksrecepit:this.videobooksrecepit,
            publisherbooksrecepit:this.publisherbooksrecepit,
            

            booksDetails:this.booksDetails,
            audiobooksDetails:this.audiobooksDetails,
            videobooksDetails:this.videobooksDetails,
            publisherbooksDetails:this.publisherbooksDetails,
            

            
            pricelistall:this.pricelistall,
            changeprice:this.changeprice,
            
            ForgetMailpassword:this.ForgetMailpassword,
            addnewsletters:this.addnewsletters,
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;