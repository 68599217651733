import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocumentaudio from "./PdfDocumentaudio";



function PdfPreviewaudio(props) {

    const {audiobooksDetails} = useContext(MyContext);
    const [isAudioBookDetails, setAudioBookDetails] = useState([]);
    useEffect( () => {
    audioRecepitDetails(props.match.params.Addpreview);
      }, []); 

      const audioRecepitDetails = async(audioids) =>{
       try {
              const audiosbooklist = await audiobooksDetails(audioids);
              console.log(audiosbooklist);
                     if(audiosbooklist.success === 1){
                       console.log(audiosbooklist.audiosbooklist);
                       setAudioBookDetails(audiosbooklist.audiosbooklist);
                     
             }
       } catch (error) { throw error;}
     }


        return(
       <div className="bookadspreview">
              
          
              <div className="container">
                     <div className="row">
                            
                     <div className="col-md-4"> &nbsp; </div>

                    <PDFDownloadLink
                                   document={<PdfDocumentaudio bookpdflist={isAudioBookDetails} />}
                                   fileName="PaymentReceipt.pdf"
                                   style={{
                                   textDecoration: 'none',
                                   padding: '12px',
                                   color: 'rgb(255 255 255)',
                                   backgroundColor: 'rgb(251, 106, 31)',
                                   marginTop: '7px',
                                   fontSize: '20px',
                                   float: 'right',
                                   borderRadius: '7px',
                                   marginLeft: '17px',
                                   }}
                            >
                                   {({ blob, url, loading, error }) =>
                                   loading ? "Loading document..." : "Download Pdf"
                                   }
                            </PDFDownloadLink>

             </div>
             </div>
             </div>
        );
    }

export default PdfPreviewaudio;
