import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner';
import logo from '../Img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  const {addnewsletters} = useContext(MyContext);
  useEffect( () => {
    window.addEventListener('scroll', toggleVisibility);
  
  }, []);
  
  const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
  const [is_visible, set_visible] = useState(false);
  const toggleVisibility =()=> {
    if (window.pageYOffset > 700) {
      set_visible(true);
    } else {
      set_visible(false);
    }
  }
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const [loadingsss, setloadingsss] = useState(false);
    const [isErrorMsg, setErrorMsg] = useState(null);
    const [isSuccessMsg, setSuccessMsg] = useState(null);
    const newsletteremails = async (event) =>{
      try{
        setloadingsss(true);
        event.preventDefault();
        event.persist();
        const data = await addnewsletters(newsletteruserInfo);
       if(data.success === true){
        setloadingsss(false);
        setSuccessMsg(data.msg);
       }else{
        setloadingsss(false);
        setErrorMsg(data.msg);
       }
      } catch (error) { throw error;} 
    }

    const [newsletteruserInfo, setnewsletteruserInfo] = useState({
      firstname: '',
      lastname:'',
      email:'',
      myinbox:'',
    });
    
    const onChangeValue = (e) => {
      setnewsletteruserInfo({
            ...newsletteruserInfo,
                [e.target.name]:e.target.value
        });
    } 

  return (<>
  <div className="Appfooter">
    <div className="container">
      <div className="row align-items-center">
      <div className="col-md-12" style={{padding:0}}>
      

              <div className="col-md-6" >
                <h1> Newsletter </h1>
        {isErrorMsg !== null && (<p className="errors__footersss"><>{isErrorMsg}</></p>)}
      {isSuccessMsg !== null && (<p className="success__footersss"><>{isSuccessMsg}</></p>)}
      <form onSubmit={newsletteremails} className="forms__nesss">
          <input type="email" name="email" value={newsletteruserInfo.email} onChange={onChangeValue} className="form-controlsss" placeholder="Your Email Address..."  required />
          {loadingsss ? <>
            <Loader type="Oval" color="#f1592b" height={47} width={47} style={{float: "right"}}/>
          </> :
            <button type="submit" className="fotter__btnsss" > Subscribe </button>
          } 
      </form>
      </div>
      <div className="col-md-6" >
      <h1> Quick Links</h1>
      <ul className="ttt7">
          <li className="menu-item"><Link exact  to="/">Home</Link></li>
          <li className="menu-item"><Link to="/RecommendedBooks/" > Recommended Books </Link></li>
          <li className="menu-item"><Link  to="/Highlighted/" > Highlighted Books </Link></li>
          <li className="menu-item"><Link  to="/FeaturedBooks/" > Featured Books </Link></li>
          <li className="menu-item"><Link  to="/StandardBooks/" > Standard Books </Link></li>
          <li className="menu-item"><Link  to="/BookTrailers" > Book Trailers </Link></li>
          <li className="menu-item"><Link to="/AudioBooks" > Audio Books </Link></li>
          <li className="menu-item"><Link to="/Publishers" > Publishers </Link></li>
          <li className="menu-item"><Link  to="/Contact" > Contact Form </Link></li>
        </ul>
      </div>
      <div className="clearfix"> </div>

      
      
      </div>
    </div>
    </div>
  </div>
  <footer className="copyright-block copyright-layout-simple">
		<div className="container">
      <div className="col-md-12">
      <div className=" logosss">
              <Link exact activeClassName='is-active' to="/">
                <img src={logo} alt="logo" /> 
              </Link>
        <p><Link to="/" > Writers.Support </Link> © All Rights Reserved - {getCurrentYear()} </p>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
	</footer>
  {is_visible && ( <>
    <span onClick={scrollToTop} className="scrolls"><FontAwesomeIcon icon={['fas','arrow-up']} className="gold" /></span><span className="ltx-hex"><span className="ltx-hex-inner"></span></span>	
  </>)}
      
    </>
  )
}

export default Footer
