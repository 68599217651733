import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocumentpublisher from "./PdfDocumentpublisher";





function PdfPreviewpublisher(props) {

    const {publisherbooksDetails} = useContext(MyContext);
    const [isPublisheDetails, setPublisheDetails] = useState([]);

    useEffect( () => {
       publisherRecepitDetails(props.match.params.Addpreview);
      }, []); 

const publisherRecepitDetails = async(publisherids) =>{
    try {
        //console.log(e.value);
           const publishersbooklist = await publisherbooksDetails(publisherids);
           console.log(publishersbooklist);
                  if(publishersbooklist.success === 1){
                    console.log(publishersbooklist.publishersbooklist);
                    setPublisheDetails(publishersbooklist.publishersbooklist);
          }
    } catch (error) { throw error;}
  }
     
        return(
       <div className="bookadspreview">
              
            
              <div className="container">
                     <div className="row">
                            
                     <div className="col-md-4"> &nbsp; </div>
                   <PDFDownloadLink
                                   document={<PdfDocumentpublisher bookpdflist={isPublisheDetails} />}
                                   fileName="PaymentReceipt.pdf"
                                   style={{
                                   textDecoration: 'none',
                                   padding: '12px',
                                   color: 'rgb(255 255 255)',
                                   backgroundColor: 'rgb(251, 106, 31)',
                                   marginTop: '7px',
                                   fontSize: '20px',
                                   float: 'right',
                                   borderRadius: '7px',
                                   marginLeft: '17px',
                                   }}
                            >
                                   {({ blob, url, loading, error }) =>
                                   loading ? "Loading document..." : "Download Pdf"
                                   }
                            </PDFDownloadLink>


             </div>
             </div>
             </div>
        );
    }

export default PdfPreviewpublisher;
