import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function AddBookTrailer(props) {

  let history = useHistory();

       
       const {rootState,pricelistall,newbooktrailersadd,impressivelatestbook} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
      

       const [userInfo, setuserInfo] = useState({
              title: '',
              url: '',
                
       });

    
       
    const [isprice, setisprice] = useState({Trailers:''});
    const [isimpresivelatest, setimpresivelatest] = useState([]);
       useEffect( () => {
              window.scrollTo(0, 0);
                  pricelist();
              impressivelatest();
       }, []); 
     
       const pricelist = async () => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data.priceall);
                     setisprice({ 
                            ...isprice,
                            Trailers:data.priceall[6].price,
                     }) ;
              }
       }

       const impressivelatest = async() =>{
              try {
                     const impressiveresult = await impressivelatestbook();
                            if(impressiveresult.success === 1){
                                   console.log(impressiveresult.bookslatestimpressive);
                                   setimpresivelatest(impressiveresult.bookslatestimpressive) ;
                     }
              } catch (error) { throw error;}
          }
     
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

       const AddbooksTrailers = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            const dataadd = await newbooktrailersadd(userInfo,theUser,isprice.Trailers);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   history.push(`/f93718ba896b0ec0ec82e0decfa8ece7/${dataadd.newids}`);
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
   
        return(
               <div className="bookads2">
                    
                     <div className="container">
                            <div className="row">
                                  
                                   <div className="col-md-9">

                                   <h3 className="yyy2"> Book Trailer  </h3>
              <form onSubmit={AddbooksTrailers} className="central-meta">
              <span className="adverts-form-required2"> <b> * Denotes A Required Field </b></span>
              <div className="clearfix"></div>
              <div className="form-row">
              <h3 className="yyy2"> Onetime Fee: <span className="cols"> ${isprice.Trailers} </span>   </h3>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Youtube Video Title <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Youtube Video Title" required />
                  </div> 
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Youtube Video Url <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="url" value={userInfo.url} onChange={onChangeValue}  className="form-control" placeholder="Youtube Video Url" required />
                  </div>
                
               
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="btn btn-primary"> Continue  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                  </div>
                   }
              
             
              </div>
              
          </form>
          </div>




          <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads+item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 





          </div>
          </div>
          </div>
        );
    }

export default AddBookTrailer;