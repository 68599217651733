import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";

import Select from 'react-select'
import ContentLoader from "react-content-loader";
function Bookupgradeplan(props) {
       let history = useHistory();
       
    const {pricelistall,upgradeplanupdate,rootState} = useContext(MyContext);
    const [isprice, setisprice] = useState({Featured:'',Highlighted:'',Recommended:'',upgradeFeatured:'',upgradeRecommended:'',upgradeHighlighted:''});
    const {theUser} = rootState;
const [isplan, setplan] = useState(null);
const [ispaymentbook, setpaymentbook] = useState();
const [isplanactive, setplanactive] = useState(false);
    useEffect( () => {
        pricelist();
       }, []); 

       const pricelist = async (event) => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data.priceall);
                     setisprice({ 
                            ...isprice,
                            Featured:data.priceall[2].price,
                            Highlighted:data.priceall[1].price,
                            Recommended:data.priceall[0].price,

                            upgradeFeatured:data.priceall[2].price-props.amount,
                            upgradeHighlighted:data.priceall[1].price-props.amount,
                            upgradeRecommended:data.priceall[0].price-props.amount,
                     }) ;
              }
       }
       const booksAddplansupdate = async (event) => {
              //window.scrollTo(0, 0);
              
              try {
                     event.preventDefault();
                     event.persist();
              if(theUser !== null){
                     const dataplanupdate = await upgradeplanupdate(props.booksid,ispaymentbook,isplan);
                     if(dataplanupdate.success === 1){
                            console.log(dataplanupdate);
                            event.target.reset();
                            props.editModepreviewchildsplan(); 
                            history.push(`/Bookupgradepay/${props.booksid}`);
                            
                            
                     }
              }
              } catch (error) { throw error;}    
       }



     const customStyles = {
       option: (provided, state) => ({
         ...provided,
         borderBottom: '1px solid #b3a099',
         color: state.isSelected ? 'yellow' : '#fb6a1f',
         backgroundColor: state.isSelected ? '#795548' : 'white',
         fontSize:20,
         fontWeight:600,
         letterSpacing:1,
         paddingTop:17,
         paddingBottom:17,
       }),
       control: (provided) => ({
         ...provided,
         marginTop: 7,
         minHeight:57,
       })
     }    

    // {isprice.upgradeFeatured === 0 || isprice.upgradeHighlighted === 0 || isprice.upgradeRecommended === 0  ? null :

     const options = [
       { value: isprice.upgradeRecommended, label: `Recommended - $${isprice.upgradeRecommended}` },
       { value: isprice.upgradeHighlighted, label: `Highlighted - $${isprice.upgradeHighlighted}` },
       
       { value: isprice.upgradeFeatured, label: `Featured - $${isprice.upgradeFeatured}` },
      
     ]

      const optionsfeatured = [
       { value: isprice.upgradeRecommended, label: `Recommended - $${isprice.upgradeRecommended}` },
       { value: isprice.upgradeHighlighted, label: `Highlighted - $${isprice.upgradeHighlighted}` },
      
     ]
       const optionsHighlighted = [
       { value: isprice.upgradeRecommended, label: `Recommended - $${isprice.upgradeRecommended}` },
       
     ]
      

     const planChange = (e) => {
            console.log(e.value);
            setplan(e.value);
            if(e.value === isprice.upgradeRecommended){
              setpaymentbook('Recommended Books');
            }
            if(e.value ===isprice.upgradeHighlighted){
              setpaymentbook('Highlighted Books');
            }
            if(e.value ===isprice.upgradeFeatured){
              setpaymentbook('Featured Books');
            }
            
            setplanactive(true);
           
   } 


        return(
               <div className="addbook">
                     <div className="container">
                            <div className="row">
                                   <div className="col-md-12">
        <h2 className="xxx"> <span className="cols">Onetime Fee: </span> Paypal / Stripe / Credit Card Payment   </h2>
                                   </div>
                                 


                                   <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Book Type <span className="adverts-form-required"> * </span> </label>
                      {props.dbplan === 'Featured Books' ? 
                            <Select name="plan"  onChange={planChange} className="qmmwwss" styles = { customStyles } options={optionsfeatured} />
                            : 
                            <>
                                   {props.dbplan === 'Highlighted Books' ? 
                                          <Select name="plan"  onChange={planChange} className="qmmwwss" styles = { customStyles } options={optionsHighlighted} />
                                          :
                                          <Select name="plan"  onChange={planChange} className="qmmwwss" styles = { customStyles } options={options} />
                                   }
                            </>
                      }
                    
                  </div>



       <form onSubmit={booksAddplansupdate}>
              {isplanactive === true ? 
                     <button type="submit" className="thm-btn rsss"> Continue <FontAwesomeIcon icon={['fas','arrow-circle-right']} /></button>
              : null }
       </form>
                

               </div>
       </div>
</div>
        );
    }

export default Bookupgradeplan;