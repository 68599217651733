import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {toast} from "react-toastify";
import {NavLink} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../Img/logo.png';

function Navbarmenu() {

  const {rootState,} = useContext(MyContext);
   const {isAuth,theUser} = rootState;
   const [isMenu, setisMenu] = useState(false);
    
  const logsss =() =>{
    toast('please login to access this page ',{
      position: "top-center"});  
  }
  const toggleClass = () => {
    //setisMenu({isMenu: !isMenu})
    setisMenu(isMenu === false ? true : false);
};

  let boxClass = ["main-menu menu-right menuq1"];
  if(isMenu) {
    boxClass.push('menuq2');
  }else{
    boxClass.push('');
  }
  return(
    <header className="header-middle">
      <div className="top_header_area">
        <div className="container">
          <div className=" col-md-12 mensswwwsss">
            <div className=" logo">
              <NavLink exact activeClassName='is-active' to="/">
                <img src={logo} alt="logo" /> 
              </NavLink>
            </div>   
            
            <nav className="navBar ">
                    <span className="res5c" style={{ display: 'none' }} onClick={toggleClass} >  <FontAwesomeIcon icon={['fas','bars']} />  </span>
                        <ul className={boxClass.join(' ')}>
                            <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/">Home</NavLink></li>

                        {isAuth === true && theUser !== null ?
                             <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/PostYourBook"> Post Your Book </NavLink></li>
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Post Your Book </NavLink></li>
                        }

                           
                           
                            <li className="menu-item has-children"><NavLink to="#"> Books<FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                                <ul className="sub-menu">
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/RecommendedBooks/" onClick={toggleClass}> Recommended Books </NavLink></li>
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/Highlighted" onClick={toggleClass}> Highlighted Books </NavLink></li>
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/FeaturedBooks/" onClick={toggleClass}> Featured Books </NavLink></li>
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/StandardBooks/" onClick={toggleClass}> Standard Books </NavLink></li>
                                
                                
                                </ul>
                            </li>
                             <li className="menu-item has-children"><NavLink to="#"> Book Trailers<FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                                <ul className="sub-menu">
                                {isAuth === true && theUser !== null ?
                                    <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/AddBookTrailer"> Post Book Trailer </NavLink></li>
                                : 
                                    <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Post Book Trailer </NavLink></li>
                                }
                                
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/BookTrailers" onClick={toggleClass}> View Book Trailers </NavLink></li>
                                  
                                
                                </ul>
                            </li>
                              <li className="menu-item has-children"><NavLink to="#">  Audio Books <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                                <ul className="sub-menu">
                                {isAuth === true && theUser !== null ?
                                    <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/AddAudio"> Post Audio Book </NavLink></li>
                                : 
                                    <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Post Audio Book </NavLink></li>
                                }
                                
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/AudioBooks" onClick={toggleClass}> View Audio Books </NavLink></li>
                                  
                                
                                </ul>
                            </li>
                             <li className="menu-item has-children"><NavLink to="#"> Publishers <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                                <ul className="sub-menu">
                                {isAuth === true && theUser !== null ?
                                    <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/AddPublisher"> Add Publisher </NavLink></li>
                                : 
                                    <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Add Publisher </NavLink></li>
                                }
                                
                                  <li className="menu-item"><NavLink activeClassName='is-active' to="/Publishers" onClick={toggleClass}> View Publishers </NavLink></li>
                                  
                                
                                </ul>
                            </li>
                            
                         
                           
                        {isAuth === true && theUser !== null ?
                            <li className="menu-item" onClick={toggleClass}><NavLink activeClassName='is-active' to="/Myaccount"> My Account </NavLink></li> 
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> My Account </NavLink></li>
                        }
                        {isAuth === false && theUser === null ? 
                             <li className="menu-item" onClick={toggleClass}><NavLink activeClassName='is-active' to="/Login"> Login/Register </NavLink></li>
                        : 
                          <li className="menu-item" onClick={toggleClass}><NavLink activeClassName='is-active' to="/Logout"> Logout </NavLink></li>
                        }


                            <li className="menu-item" onClick={toggleClass}><NavLink activeClassName='is-active' to="/Search"> <FontAwesomeIcon icon={['fas','search']} className="searchsss" /> </NavLink></li> 
                           
                            
                        </ul>
                    </nav>
          </div>
        </div>
      </div>
            
           
       
            </header>
        );
    }

export default Navbarmenu;