import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardAccount from './DashboardAccount';
import Profiles from './Profile';
import Pendingall from './Pending';
import Adminall from './Admin';
import Logout from './Logout';
import Recepitpayment from './Recepitpayment';

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;




function Myaccount() {

  const {rootState,impressivelatestbook} = useContext(MyContext);
   const {isAuth,theUser} = rootState;
   const [isDashboard, setisDashboard] = useState(false);
   const [isProfile, setisProfile] = useState(false);
   const [isLogOut, setisLogOut] = useState(false);
   const [isPending, setisPending] = useState(false);
   const [isAdmin, setisAdmin] = useState(false);
   const [isRecepit, setisRecepit] = useState(false);
   const [isimpresivelatest, setimpresivelatest] = useState([]);

  useEffect( () => {
    window.scrollTo(0, 0);
    impressivelatest();
   
}, []);

const impressivelatest = async() =>{
  try {
         const impressiveresult = await impressivelatestbook();
                if(impressiveresult.success === 1){
                       console.log(impressiveresult.bookslatestimpressive);
                       setimpresivelatest(impressiveresult.bookslatestimpressive) ;
         }
  } catch (error) { throw error;}
}

 const Dashboard =()=>{
       setisDashboard(false);
       setisProfile(false);
       setisLogOut(false);
       setisAdmin(false);
       setisPending(false);
       setisRecepit(false);
     }
 const Profile =()=>{
        setisDashboard(true);
        setisProfile(true);
        setisLogOut(false);
        setisAdmin(false);
        setisPending(false);
        setisRecepit(false);
     }
const Pending =()=>{
        setisDashboard(true);
        setisPending(true);
        setisAdmin(false);
        setisProfile(false);
        setisLogOut(false);
        setisRecepit(false);
     }
const Admin =()=>{
        setisDashboard(true);
        setisPending(false);
        setisAdmin(true);
        setisProfile(false);
        setisLogOut(false);
        setisRecepit(false);
     }
const Recepit =()=>{
        setisDashboard(true);
        setisPending(false);
        setisAdmin(false);
        setisRecepit(true);
        setisProfile(false);
        setisLogOut(false);
     }

 const LogOut =()=>{
        setisDashboard(true);
        setisProfile(false);
        setisAdmin(false);
        setisLogOut(true);
        setisPending(false);
        setisRecepit(false);
     }
//      adminpayment =()=>{
//        this.setState({ 
//         isDashboard: true,
//         isClassified: false,
       
//         isPassword: false,
//         isMedia: false,
//         isLogOut: false,
//         isadminpayment: true,
//         isadmincoupen: false,
//        });
//      }
    

     

    return (
       <div className="members">
        <div className="container">
          <div className="row">
           
              <div className="col-md-9" style={{padding:0}}>
              <div className="breadcrumbs">
                <div className="content-box">
                  <h1> My Account </h1>
                </div>
              </div>
              <ul className="memberstabsss">
                     <li onClick={Dashboard} className={isDashboard? "nav-item": "active" } >
                     <FontAwesomeIcon icon={['fas','th-large']} className="iconsss2" />
                     Dashboard 
                     </li>
                  
                    
                     <li onClick={Profile} className={ isProfile ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     Profile Settings </li> 

                   <li onClick={Pending} className={ isPending ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Payment Pending </li> 
                     {theUser === 2 || theUser === 6 ?
                    <li onClick={Admin} className={ isAdmin ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Admin </li> 
                     : null }
                   
                 
                     
                     <li onClick={Recepit} className={isRecepit? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />
                     receipt
                     </li> 

                     <li onClick={LogOut} className={isLogOut? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />
                     Log Out
                     </li>
              </ul>

             
              <div className="col-md-12 aaa6">
              {isAuth === true && theUser !== null ?
                     <div className="card card-body">
                    
                            {isDashboard !== true && theUser !=='' ?
                                   <>
                                     <DashboardAccount  storageid={theUser} />
                                   </>
                                   : null } 
                          
                          
                            {isProfile === true ?
                                   <>
                                   <Profiles storageid={theUser}  />
                                   </>
                                   : null }
                            {isPending === true ?
                                   <>
                                   <Pendingall storageid={theUser}  />
                                   </>
                                   : null }
                             {isAdmin === true ?
                                   <>
                                   <Adminall storageid={theUser}  />
                                   </>
                                   : null }
                            
                      
                                  {isRecepit === true ?
                                          <Recepitpayment /> 
                                   : null } 
                                   
                                  {isLogOut === true ?
                                          <Logout /> 
                                   : null } 

                     </div>
                     : null }
              </div>



              </div>




              <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 

       </div>
      </div>
      



       </div>
    );
  }
export default Myaccount;