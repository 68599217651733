import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";
import Bookedit from './Bookedit';

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import StripeCheckout from "react-stripe-checkout";

import { useHistory } from "react-router-dom";

import { WEB_URL,WEB_URL_UPLOAD,API_KEY_STRIPE } from '../contexts/Apilinks';

let baseURLpaypal= WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Bookupgradepay(props) {

       let history = useHistory();
    const {newbookaddpreview,rootState,bookstripeupgrade,impressivelatestbook} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [isEditingplan, setisEditingplan] = useState(false);
    const [ispaymentprice, setpaymentprice] = useState(false);
    const [ispaymentbookid, setispaymentbookid] = useState(false);
    const [paynowpaypal, setpaynowpaypal] = useState(false);
    const [paynowstripe, setpaynowstripe] = useState(true);
    const [ispublish, setispublish] = useState(false);
    const [isimpresivelatest, setimpresivelatest] = useState([]);
    useEffect( () => {
       booklistpreview(props.match.params.Addpreview);
       impressivelatest();
      }, []); 
   
      const impressivelatest = async() =>{
       try {
              const impressiveresult = await impressivelatestbook();
                     if(impressiveresult.success === 1){
                            console.log(impressiveresult.bookslatestimpressive);
                            setimpresivelatest(impressiveresult.bookslatestimpressive) ;
              }
       } catch (error) { throw error;}
   }

      const publish = () => {
       setispublish(true);
      }
      const booklistpreview = async (ids) => {
       window.scrollTo(0, 0);
       try {
             
                     const dataaddpreview = await newbookaddpreview(ids);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview.bookpreviewsall);
                            setisbookpreview(dataaddpreview.bookpreviewsall);
                            setpaymentprice(dataaddpreview.bookpreviewsall[0].upgradeplanamount)
                            setispaymentbookid(dataaddpreview.bookpreviewsall[0].books_id)
                     }
              } catch (error) { throw error;}    
       }
      
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              booklistpreview(props.match.params.Addpreview);
       }
       const editModepreviewchildeditplan =() =>{
              setisEditingplan(false);
              booklistpreview(props.match.params.Addpreview);
       }
   
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }



   const makePayment = async (token) => {
          console.log(ispaymentbookid);
          console.log(ispaymentprice);
       try {
              const datastripe = await bookstripeupgrade(token,theUser,ispaymentbookid,ispaymentprice);
              console.log(datastripe);
              if(datastripe.success === 1){
                     history.push(`/StripeBookupgradeSuccess/${datastripe.stripeid}`);

                  } 
       } catch (error) { throw error;}    
   }


   
   const paypalMode = () => {
       setpaynowpaypal(true);
       setpaynowstripe(false);
   }
  const stripeMode = () => {
       setpaynowpaypal(false);
       setpaynowstripe(true);
   }

        return(
       <div className="bookadspreview">
              
              <div className="container">
                     <div className="row">
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.books_id} className="col-md-9" style={{padding:0,}}> 
                             {theUser === item.user_id ? <>  
                     {isEditing === true && isEditingplan === false ?
                             <Bookedit booksid={props.match.params.Addpreview} title={item.title} image={item.imagefront} description={item.description} price={item.price} websitelink={item.websitelink} purchaselink={item.purchaselink} category={item.category} other={item.other} editModepreviewchilds={editModepreviewchildedit } />

                             : <>
                            {isEditingplan === true && isEditing === false ?
                            //  <Bookeditplan booksid={props.match.params.Addpreview} editModepreviewchildsplan={editModepreviewchildeditplan }/> 
                            null
                            : <>
                     
                             

                         
                          <div className="col-md-12 bookadspreviewlsss">
                          <h1> Onetime Fee : ${item.upgradeplanamount}</h1>
                          
                     <hr/>
                            <h3>Payment Plan : <span>{item.upgradeplan} </span></h3>
                           




                          <div className="clearfix masssqqq"></div>
                            <h1>Choose your payment method</h1>
                            <hr/>
                          <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                  
                            <p className="pymt-type-name"> <span className="stripsss"> Stripe </span></p>
                           
                     </div>
                    
                     
                     </div>

                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                    
                            <p className="pymt-type-name"><span className="Pay">Pay</span><span className="pal">pal</span></p>
                          
                     </div>
                    
                     
                     </div>
                     <hr/>
 




                          <div className="clearfix"></div>
              {paynowstripe === true ? 
                          <StripeCheckout stripeKey={API_KEY_STRIPE} token={makePayment} name={item.upgradeplan} description=" Onetime Fee" amount={item.upgradeplanamount * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="thm-btn pricing-one__btn"> Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /> </div>
                     </StripeCheckout>
              : 

                     <a href={baseURLpaypal+`paypalupgrade/pay/`+theUser+`/`+item.books_id+`/`+item.upgradeplanamount}  className="thm-btn pricing-one__btn" > Pay Now <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /></a>


              }

                     
                            </div>
                             
                            
                      </>
                     }
                     </>
                     }


</>: null}
                      </div>
                      ))}



<div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                   <img src={BaseURLuploads + item.imagefront} alt="books" />
                                     
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 






             </div>
             </div>
             </div>
        );
    }

export default Bookupgradepay;