import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";
import Select from 'react-select';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;

function Bookadd(props) {

  let history = useHistory();

       
       const {rootState,newbookadd,impressivelatestbook,pricelistall} = useContext(MyContext);
       const {theUser} = rootState;
       
       const [loadingadd, setloadingadd] = useState(false);
       let editorState = EditorState.createEmpty();
       const [description, setDescription] = useState(editorState);
       const [isimpresivelatest, setimpresivelatest] = useState([]);
       const [userInfo, setuserInfo] = useState({
              title: '',
              price: '',
              purchase: '',
              website: '',
              file:'',
              category:'',
              descothers:'',
                
       });
       const [isprice, setisprice] = useState({Recommended:'',Highlighted:'',Featured:'',Standard:''});
       
      
       const [isplan, setplan] = useState(null);
       const [ispaymentbook, setpaymentbook] = useState();
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       const [showResults, setshowResults] = useState(false);

       useEffect( () => {
           
        window.scrollTo(0, 0);
        impressivelatest();
        pricelist();
           }, []); 

           const pricelist = async (event) => {
              const data = await pricelistall();
              if(data.success === 1){
                     console.log(data.priceall);
                     setisprice({ 
                            ...isprice,
                            Recommended:data.priceall[0].price,
                            Highlighted:data.priceall[1].price,
                            Featured:data.priceall[2].price,
                            Standard:data.priceall[3].price,
                     }) ;
              }
       }
       
           const impressivelatest = async() =>{
              try {
                     const impressiveresult = await impressivelatestbook();
                            if(impressiveresult.success === 1){
                                   console.log(impressiveresult.bookslatestimpressive);
                                   setimpresivelatest(impressiveresult.bookslatestimpressive) ;
                     }
              } catch (error) { throw error;}
          }

       const categoryMode = (event) => {
              if(event.target.value === 'other')
              { 
                     setshowResults(true);
              }else{
                     setshowResults(false);
              }
          
        } 

       const html = '';
       const contentBlock = htmlToDraft(html);
       if (contentBlock) {
         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         //const editorState = EditorState.createWithContent(contentState);
         //setDescription(editorState);
       }
       //text editior
       const onEditorStateChange = (editorState) => {
              setDescription(editorState);
            }
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 


       const booksAddbooks = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                            if(isplan === null){
                              toast.error('Please Select Book Type', {
                                position: "top-center",hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }

                            const dataadd = await newbookadd(userInfo,userInfo.description.value,theUser,isplan,ispaymentbook);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   history.push(`/5ba3c8d60e477b46681416aa408156ad/${dataadd.newids}`);
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
             
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 




     const customStyles = {
       option: (provided, state) => ({
         ...provided,
         borderBottom: '1px solid #b3a099',
         color: state.isSelected ? 'yellow' : '#fb6a1f',
         backgroundColor: state.isSelected ? '#795548' : 'white',
         fontSize:20,
         fontWeight:600,
         letterSpacing:1,
         paddingTop:17,
         paddingBottom:17,
       }),
       control: (provided) => ({
         ...provided,
         marginTop: 7,
         minHeight:57,
       })
     }     
     const options = [
       { value: isprice.Recommended, label: `Recommended - $${isprice.Recommended}` },
       { value: isprice.Highlighted, label: `Highlighted - $${isprice.Highlighted}` },
       { value: isprice.Featured, label: `Featured - $${isprice.Featured}` },
       { value: isprice.Standard, label: `Standard - $${isprice.Standard}` }
     ]

     const planChange = (e) => {
            console.log(e.value);
            setplan(e.value);
            if(e.value === isprice.Recommended){
              setpaymentbook('Recommended Books');
            }
            if(e.value ===isprice.Highlighted){
              setpaymentbook('Highlighted Books');
            }
            if(e.value ===isprice.Featured){
              setpaymentbook('Featured Books');
            }
            if(e.value ===isprice.Standard){
              setpaymentbook('Standard Books');
            }
   } 




        return(
               <div className="bookads2">
                      
                     <div className="container">
                            <div className="row">
                                   <div className="col-md-9">
                                  
       
                                   <h3 className="yyy2"> Add  Book Information  </h3>
              <form onSubmit={booksAddbooks} className="central-meta">
              <span className="adverts-form-required2"> <b> * Denotes A Required Field </b></span>
              <span className="adverts-form-required2"> <b> Recommended Books will be displayed in all inner pages at right side. </b></span>
              <div className="clearfix"></div>
              <div className="form-row">
              <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Book Type <span className="adverts-form-required"> * </span> </label>
                     
                      <Select name="plan"  onChange={planChange} className="qmmwwss" styles = { customStyles } options={options} />
                          
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Title" required />
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Book Cover Image <span className="adverts-form-required"> * </span> </label>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                     
                  </div>
                  {filetemp && (
                    <div style={{ textAlign: "center" }}>
                         <img className="previewimg"  src={filetemp} alt="UploadImage" />
                        <button onClick={resetFile} className="removeqqq">Remove File</button>
                    </div>
                    )}
                    {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                    <div className="form-group col-md-12 editor">
                    <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            
                        />
                     <textarea style={{display:'none'}}
                     disabled ref={(val) => userInfo.description = val}
                    
                     value={draftToHtml(convertToRaw(description.getCurrentContent())) }
                     />
                    </div>
  
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Price  </label>
                      <input type="text" name="price" value={userInfo.price} onChange={onChangeValue}  className="form-control" placeholder="Price" />
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Book Purchase Link  </label>
                      <input type="text" name="purchase" value={userInfo.purchase} onChange={onChangeValue}  className="form-control" placeholder="Book Purchase Link" />
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Category  <span className="adverts-form-required"> * </span> </label>
                      <select  name="category" value={userInfo.category} onChange={onChangeValue} className="wqsss form-control" onClick={categoryMode} required>
                              
                              <option value=""> Choose the field </option>
                              <option>Fiction &amp; Literature</option>
                              <option>Romance</option>
                              <option>Science Fiction &amp; Fantasy</option>
                              <option>Comics &amp; Graphic Novels</option>
                              <option>Fiction - YA</option>
                              <option>Periodicals</option>
                              <option>Kids</option>
                              <option>Non-Fiction</option>
                              <option>Business &amp; Finance</option>
                              <option>Biography &amp; Memoir</option>
                              <option>Mystery &amp; Suspense</option>
                              <option value="other">Other</option>
                              
                            </select>
                            { showResults ? 
                           <div>
                           <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" value={userInfo.descothers} onChange={onChangeValue}  defaultValue="" />
                           </div>
                            
                            : 
                            <div>
                           <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" value={userInfo.descothers} onChange={onChangeValue}  style={{display:'none'}}/>
                           </div>
                           }
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Author Website  </label>
                      <input type="text" name="website" value={userInfo.website} onChange={onChangeValue}   className="form-control" placeholder="Author Website" />
                  </div>     
                 
               
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="btn btn-primary"> Continue  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                  </div> 
                   }
              
             
              </div>
              
          </form>
          </div>
          <div className="col-md-3 lll">
                       <div className="">
                       <h1> <span className="cols"> Recommended  </span> Books </h1>
                       {isimpresivelatest.length > 0 ? <>
                   
                     {isimpresivelatest.map((item, index) => ( 
                              <div key={item.books_id} className="col-md-12">
                              <div className="aaa4">
                             
                <Link key={item.books_id} to={`/Bookpreview/${item.books_id}`}>
              
                   <section className="app" id="app" data-current-media="book">
                  
                     
                               <div className="book-wrapper2">
                                     <img src={BaseURLuploads + item.imagefront} alt="books" />
                                    
                               </div>
                               
                              <h4> {item.title} </h4>
                              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                             </section>    
       
               
            
                                   </Link>
                
                </div> 
                </div> 
                            )) }
                         
                            </>
                            : <ContentLoader height={300} width={500} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                           <rect x="37" y="27" rx="0" ry="0" width="227" height="300"/> 
                          
                             </ContentLoader>  }
                         </div>
                     
                         </div> 




          </div>
          </div>
          </div>
        );
    }

export default Bookadd;