
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { PDFDownloadLink } from "@react-pdf/renderer";

import PdfDocument from "./PdfDocumentBook";
import PdfDocumentaudio from "./PdfDocumentaudio";
import PdfDocumentvideo from "./PdfDocumentvideo";
import PdfDocumentpublisher from "./PdfDocumentpublisher";

import Select from 'react-select';

function Recepitpayment() {
    const {rootState,booksrecepit,booksDetails,audiobooksrecepit,videobooksrecepit,publisherbooksrecepit,audiobooksDetails,videobooksDetails,publisherbooksDetails} = useContext(MyContext);
    const {theUser} = rootState;
    const [selectOptions, setselectOptions] = useState([]);
    const [selectOptionsaudio, setselectOptionsaudio] = useState([]);
    const [selectOptionsvideo, setselectOptionsvideo] = useState([]);
    const [selectOptionspublisher, setselectOptionspublisher] = useState([]);


  const [isBook, setBook] = useState([]);
  const [isBookDetails, setBookDetails] = useState([]);

  const [isAudioBook, setAudioBook] = useState([]);
  const [isAudioBookDetails, setAudioBookDetails] = useState([]);

  const [isVideoBook, setVideoBook] = useState([]);
  const [isVideoBookDetails, setVideoBookDetails] = useState([]);

  const [isPublisherBook, setPublisheBook] = useState([]);
  const [isPublisheDetails, setPublisheDetails] = useState([]);

  const [show, setHide] = useState(false);
  const [showaudio, setHideaudio] = useState(false);
  const [showvideo, setHidevideo] = useState(false);
  const [showpublisher, setHidepublisher] = useState(false);


  useEffect( () => {
    window.scrollTo(0, 0);
    booksrecepitlist(theUser);
    audiobooksrecepitlist(theUser);
    videobooksrecepitlist(theUser);
    publisherbooksrecepitlist(theUser);
   
}, []);

const booksrecepitlist = async(userid) =>{
    try {
           const booklist = await booksrecepit(userid);
                if(booklist.success === 1){
                    console.log(booklist.bookrecepiteall);
                    const data = booklist.bookrecepiteall;
                    const options = data.map(item => ({
                            "value" : item.books_id,
                            "label" : item.title,
                    }))
                    setselectOptions(options);
                    setBook(booklist.bookrecepiteall);
                 }
    } catch (error) { throw error;}
  }
const audiobooksrecepitlist = async(userid) =>{
    try {
           const audiobooklist = await audiobooksrecepit(userid);
                if(audiobooklist.success === 1){
                    console.log(audiobooklist.audiobookrecepiteall);
                    const dataaudio = audiobooklist.audiobookrecepiteall;
                    const optionsaudio = dataaudio.map(item => ({
                            "value" : item.audio_id,
                            "label" : item.title,
                    }))
                    setselectOptionsaudio(optionsaudio);
                    setAudioBook(audiobooklist.audiobookrecepiteall);
                 }
    } catch (error) { throw error;}
  }
const videobooksrecepitlist = async(userid) =>{
    try {
           const videobooklist = await videobooksrecepit(userid);
                if(videobooklist.success === 1){
                    console.log(videobooklist.videobookrecepiteall);
                    const datavideo = videobooklist.videobookrecepiteall;
                    const optionsvideo = datavideo.map(item => ({
                            "value" : item.video_id,
                            "label" : item.title,
                    }))
                    setselectOptionsvideo(optionsvideo);
                    setVideoBook(videobooklist.videobookrecepiteall);
                 }
    } catch (error) { throw error;}
  }
const publisherbooksrecepitlist = async(userid) =>{
    try {
           const publisherbooklist = await publisherbooksrecepit(userid);
                if(publisherbooklist.success === 1){
                    console.log(publisherbooklist.publisherbookrecepiteall);
                    const datapublisher = publisherbooklist.publisherbookrecepiteall;
                    const optionspublisher = datapublisher.map(item => ({
                            "value" : item.publisher_id,
                            "label" : item.title,
                    }))
                    setselectOptionspublisher(optionspublisher);
                    setPublisheBook(publisherbooklist.publisherbookrecepiteall);
                 }
    } catch (error) { throw error;}
  }





  const RecepitBookDetails = async(e) =>{
    try {
        //console.log(e.value);
           const booklist = await booksDetails(e.value);
           console.log(booklist);
                  if(booklist.success === 1){
                    console.log(booklist.bookdetailsall);
                    setBookDetails(booklist.bookdetailsall);
                    setHide(true);
          }
    } catch (error) { throw error;}
  }
const audioRecepitDetails = async(e) =>{
    try {
        //console.log(e.value);
           const audiosbooklist = await audiobooksDetails(e.value);
           console.log(audiosbooklist.audiosbooklist);
                  if(audiosbooklist.success === 1){
                    console.log(audiosbooklist.audiosbooklist);
                    setAudioBookDetails(audiosbooklist.audiosbooklist);
                    setHideaudio(true);
          }
    } catch (error) { throw error;}
  }
const videoRecepitDetails = async(e) =>{
    try {
        //console.log(e.value);
           const videosbooklist = await videobooksDetails(e.value);
           console.log(videosbooklist);
                  if(videosbooklist.success === 1){
                    console.log(videosbooklist.videosbooklist);
                    setVideoBookDetails(videosbooklist.videosbooklist);
                    setHidevideo(true);
          }
    } catch (error) { throw error;}
  }
const publisherRecepitDetails = async(e) =>{
    try {
        //console.log(e.value);
           const publishersbooklist = await publisherbooksDetails(e.value);
           console.log(publishersbooklist);
                  if(publishersbooklist.success === 1){
                    console.log(publishersbooklist.publishersbooklist);
                    setPublisheDetails(publishersbooklist.publishersbooklist);
                    setHidepublisher(true);
          }
    } catch (error) { throw error;}
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #b3a099',
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? '#795548' : 'white',
      fontSize:20,
      fontWeight:600,
      letterSpacing:1,
      paddingTop:17,
      paddingBottom:17,
    }),
    control: (provided) => ({
      ...provided,
      marginTop: 7,
      minHeight:57,
    })
  }     
 


  return (
    <div className="container">
        <h3 className="myaccount-content"> Payment Receipt </h3>
        {isBook.length > 0 ?  <>
      <h2 className="awrawsss"> Books </h2>
      <div className="form-group col-md-12">
                      <Select name="plan"  onChange={RecepitBookDetails} className="qmmwwss" styles = { customStyles } options ={selectOptions} />
                  </div>
      {show &&<PDFDownloadLink
        document={<PdfDocument bookpdflist={isBookDetails} />}
        fileName="PaymentReceipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '12px',
          color: 'rgb(255 255 255)',
          backgroundColor: 'rgb(251, 106, 31)',
          marginTop: '7px',
          fontSize: '20px',
          float: 'left',
          borderRadius: '7px',
          marginLeft: '17px',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}

      </>
      : null }

<div className="clearfix"></div>


{isAudioBook.length > 0 ?  <>
      <h2 className="awrawsss"> Audio Books </h2>
      <div className="form-group col-md-12">
                      <Select name="planaudio"  onChange={audioRecepitDetails} className="qmmwwss" styles = { customStyles } options ={selectOptionsaudio} />
                  </div>
      {showaudio &&<PDFDownloadLink
        document={<PdfDocumentaudio bookpdflist={isAudioBookDetails} />}
        fileName="PaymentReceipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '12px',
          color: 'rgb(255 255 255)',
          backgroundColor: 'rgb(251, 106, 31)',
          marginTop: '7px',
          fontSize: '20px',
          float: 'left',
          borderRadius: '7px',
          marginLeft: '17px',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}

      </>
      : null }

<div className="clearfix"></div>

{isVideoBook.length > 0 ?  <>
      <h2 className="awrawsss"> Books Trailers </h2>
      <div className="form-group col-md-12">
                     
                      <Select name="planvideo"  onChange={videoRecepitDetails} className="qmmwwss" styles = { customStyles } options ={selectOptionsvideo} />
                  </div>
      {showvideo &&<PDFDownloadLink
        document={<PdfDocumentvideo bookpdflist={isVideoBookDetails} />}
        fileName="PaymentReceipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '12px',
          color: 'rgb(255 255 255)',
          backgroundColor: 'rgb(251, 106, 31)',
          marginTop: '7px',
          fontSize: '20px',
          float: 'left',
          borderRadius: '7px',
          marginLeft: '17px',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}

      </>
      : null }

  <div className="clearfix"></div>

{isPublisherBook.length > 0 ?  <>
      <h2 className="awrawsss"> Publisher </h2>
      <div className="form-group col-md-12">
                    
                      <Select name="planpublisher"  onChange={publisherRecepitDetails} className="qmmwwss" styles = { customStyles } options ={selectOptionspublisher} />
                     
                  </div>
      {showpublisher &&<PDFDownloadLink
        document={<PdfDocumentpublisher bookpdflist={isPublisheDetails} />}
        fileName="PaymentReceipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '12px',
          color: 'rgb(255 255 255)',
          backgroundColor: 'rgb(251, 106, 31)',
          marginTop: '7px',
          fontSize: '20px',
          float: 'left',
          borderRadius: '7px',
          marginLeft: '17px',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}

      </>
      : null }















    </div>
  );
}
export default Recepitpayment;