import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";

function PublisherEdit(props) {

 

       
       const {rootState,newpublishersupdate} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
       let editorState = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(props.description)
              ));
             const [description, setDescription] = useState(editorState);
             const [fileempty, setfileempty] = useState(false);
       const [userInfo, setuserInfo] = useState({
              title: props.title,
              email: props.email,
              phone: props.phone,
              website: props.websitelink,
              file:'',
                
       });

       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       

       useEffect( () => {
              window.scrollTo(0, 0);
       }, []); 
     
     


       const html = '';
       const contentBlock = htmlToDraft(html);
       if (contentBlock) {
         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         //const editorState = EditorState.createWithContent(contentState);
         //setDescription(editorState);
       }
       //text editior
       const onEditorStateChange = (editorState) => {
              setDescription(editorState);
            }
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

       const publisherAddbooks = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                                   toast.error(invalidImage, {
                                     position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                   }); 
                                   setloadingadd(false);
                                   return;
                                 }
                            const dataadd = await newpublishersupdate(userInfo,userInfo.description.value,theUser,props.publisher_id,userInfo.fileempty.value);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                   //console.log(userInfo.description.value);
                                   setloadingadd(false);
                                   props.editModepreviewchilds();
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}
       const cancel = () => {
              props.editModepreviewchilds();
       }
       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

       
        return(
               <div className="bookads2">
                     <div className="container">
                            <div className="row">
                                  
                                   <div className="col-md-12">

                                   <h2 className="yyy2"> Edit Publisher Profile  </h2>
              <form onSubmit={publisherAddbooks} className="central-meta">
              <span className="adverts-form-required2"> <b> * Denotes A Required Field </b></span>
              <div className="clearfix"></div>
              <div className="form-row">
              {/* <h2 className="yyy2"> Onetime Fee: <span className="cols"> ${isprice.Publisher} </span>   </h2> */}
             
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Publisher Title  <span className="adverts-form-required"> * </span> </label>
                      <input type="text" name="title" value={userInfo.title} onChange={onChangeValue}  className="form-control" placeholder="Title" required />
                  </div>
                  
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Publisher Logo Or Image <span className="adverts-form-required"> * </span> </label>
                      <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   />
                      {fileempty === true ? 

                            <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                     : 
                            <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                     }



                  </div>
                  {filetemp && (
                    <div style={{ textAlign: "center" }}>
                         <img className="previewimg"  src={filetemp} alt="UploadImage" />
                        <button onClick={resetFile} className="removeqqq">Remove File</button>
                    </div>
                    )}
                    {invalidImage !== null ? <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p> : null }
                    <div className="form-group col-md-12 editor">
                    <label className="font-weight-bold"> About Publisher  <span className="adverts-form-required"> * </span> </label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            
                        />
                     <textarea style={{display:'none'}}
                     disabled ref={(val) => userInfo.description = val}
                    
                     value={draftToHtml(convertToRaw(description.getCurrentContent())) }
                     />
                    </div>
                   
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Publisher Email <span className="adverts-form-required"> * </span> </label>
                      <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}  className="form-control" placeholder="Publisher Email" required />
                  </div>
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold">  Publisher Phone(Optional) </label>
                      <input type="text" name="phone" value={userInfo.phone} onChange={onChangeValue}  className="form-control" placeholder="Publisher Phone" />
                  </div>
                
                  <div className="form-group col-md-12">
                      <label className="font-weight-bold"> Publisher Website  </label>
                      <input type="text" name="website" value={userInfo.website} onChange={onChangeValue}   className="form-control" placeholder="Publisher Website" />
                  </div>     
                 
               
         
                  {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
              <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="btn btn-primary"> Continue  <FontAwesomeIcon icon={['fas','angle-double-right']} /></button>
                      <p onClick={cancel} className="btn btn-primary2"> Cancel  </p>
                  </div>
                   }
              
             
              </div>
              
          </form>
          </div>
          </div>
          </div>
          </div>
        );
    }

export default PublisherEdit;