import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocumentvideo from "./PdfDocumentvideo";





function PdfPreviewvideo(props) {

    const {videobooksDetails} = useContext(MyContext);
    const [isVideoBookDetails, setVideoBookDetails] = useState([]);

    useEffect( () => {
       videoRecepitDetails(props.match.params.Addpreview);
      }, []); 

      const videoRecepitDetails = async(videoids) =>{
       try {
              const videosbooklist = await videobooksDetails(videoids);
              console.log(videosbooklist);
                     if(videosbooklist.success === 1){
                       console.log(videosbooklist.videosbooklist);
                       setVideoBookDetails(videosbooklist.videosbooklist);
             }
       } catch (error) { throw error;}
     }
     
        return(
       <div className="bookadspreview">
              
            
              <div className="container">
                     <div className="row">
                            
                     <div className="col-md-4"> &nbsp; </div>
                   <PDFDownloadLink
                                   document={<PdfDocumentvideo bookpdflist={isVideoBookDetails} />}
                                   fileName="PaymentReceipt.pdf"
                                   style={{
                                   textDecoration: 'none',
                                   padding: '12px',
                                   color: 'rgb(255 255 255)',
                                   backgroundColor: 'rgb(251, 106, 31)',
                                   marginTop: '7px',
                                   fontSize: '20px',
                                   float: 'right',
                                   borderRadius: '7px',
                                   marginLeft: '17px',
                                   }}
                            >
                                   {({ blob, url, loading, error }) =>
                                   loading ? "Loading document..." : "Download Pdf"
                                   }
                            </PDFDownloadLink>


             </div>
             </div>
             </div>
        );
    }

export default PdfPreviewvideo;
