import React,{useContext,useState,useEffect} from 'react';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';

import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import "react-web-tabs/dist/react-web-tabs.css";


import {toast} from "react-toastify";
import ContentLoader from "react-content-loader";
import bee from '../Img/round.png';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;

function Home() {
       const {rootState,impresivebooks,highlightedbooks,slidertrailers,slidefeatured,sliderstandard,sliderAudio,sliderPublisherqqq,latestallbookslist} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
       const [isimpresive, setimpresive] = useState([]);
       const [ishighlighted, sethighlighted] = useState([]);
       const [databooktrailers, setDatabooktrailers] = useState([]);
       const [databookFeatured, setDatabookFeatured] = useState([]);
       const [databookStandard, setDatabookStandard] = useState([]);
       
       const [databookAudio, setDatabookAudio] = useState([]);
       const [databookPublisher, setDataPublisher] = useState([]);
       const [isallbookslatest, setallbookslatest] = useState([]);

       const logsss =() =>{
              toast('please login to access this page ',{
                     position: "top-center"});  
            }

       useEffect( () => {
              impresive();
              highlighted();
              slidersbooksAudio();
              sliderPublisher();
              slidersbookstrailers();
              slidersFeatured();
              slidersStandard();
              latestallbooks();

       }, []);

const impresive = async() =>{
       try {
              const sliderresult = await impresivebooks();
                     if(sliderresult.success === 1){
                            setimpresive(sliderresult.booksimpresivesss) ;
              }
       } catch (error) { throw error;}
 }
 const highlighted = async() =>{
       try {
              const highlightedresult = await highlightedbooks();
                     if(highlightedresult.success === 1){
                            sethighlighted(highlightedresult.bookslatesthighlighted) ;
              }
       } catch (error) { throw error;}
 }
 const slidersbooksAudio = async() =>{
       try {
              const Audioresult = await sliderAudio();
                     if(Audioresult.success === 1){
                            setDatabookAudio(Audioresult.booksAudio) ;
              } 
       } catch (error) { throw error;}
}
 const sliderPublisher = async() =>{
       try {
              const sliderPublishersss = await sliderPublisherqqq();
                     if(sliderPublishersss.success === 1){
                            setDataPublisher(sliderPublishersss.booksPublisher) ;
              }
       } catch (error) { throw error;}
}
const latestallbooks = async() =>{
       try {
              const latestallbookssss = await latestallbookslist();
                     if(latestallbookssss.success === 1){
                            setallbookslatest(latestallbookssss.bookslistlatest) ;
              }
       } catch (error) { throw error;}
 } 
 const slidersbookstrailers = async() =>{
       try {
              const trailersresult = await slidertrailers();
                     if(trailersresult.success === 1){
                            setDatabooktrailers(trailersresult.bookstrailerslatest) ;
              }
       } catch (error) { throw error;}
}
const slidersFeatured = async() =>{
       try {
              const Featuredresult = await slidefeatured();
                     if(Featuredresult.success === 1){
                            setDatabookFeatured(Featuredresult.booksFeatured) ;
              }
       } catch (error) { throw error;}
}
const slidersStandard = async() =>{
  try {
    const Standardresult = await sliderstandard();
    if(Standardresult.success === 1){
      setDatabookStandard(Standardresult.booksStandard) ;
    }
  } catch (error) { throw error;}
}
const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

const loaderhighlighted = [1,2,3,4,5];
const loadervideo = [1,2,3,4,5,6];
const loaderfeatured = [1,2,3,4,5,6,7,8];
const loaderstandard = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];

const externallinks = (url) => {
  if (!!url && !!url.trim()) { 
         url = url.trim(); 
         if (!/^(https?:)?\/\//i.test(url)) { 
             url = 'http://' + url; 
             window.open(url, '_blank');
         }else{
                window.open(url, '_blank');
         }
     } 
} 

  return (
       <div className="col-md-12" style={{padding:0}}>
       
      
<div className="topslider6 Recommended__homes">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      <div className="col-md-3" style={{padding:0}}>
        <h1> Top <span className="cols">  Reads </span>  </h1>
        </div>
        <div className="col-md-9 aaalasssqqq2"> 
        {isallbookslatest.length > 0 ? <>
                {isallbookslatest.map((item, index) => ( 
                  <div key={item.books_id} className=" aaalasssqqq">  
                  <div className="aaalasssqqq2">   
                      <Link to={`/Bookpreview/${item.books_id}`}>
                        <div className="">
                          <img src={BaseURLuploads+item.imagefront} alt="books"   className="img-fluid"/>
                        </div>
                      </Link>
                    </div>   
                  </div>      
                )) }
              </>:  null }
              </div>
      </div>
      {isimpresive.length > 0 ? <>
        <div className="col-md-3 " style={{padding:0}}>
        <div className="Recommended__homes__Lefts" >
        {isimpresive.slice(2,6).map((item, index) => ( 
          <div  key={item.books_id} className="col-md-12" style={{padding:0}}>
             <div className="col-md-4" style={{padding:0}}> 
              <Link to={`/Bookpreview/${item.books_id}`}>
                <div className="Recommended__homes__coverLeft">
                  <img src={BaseURLuploads+item.imagefront} alt="books"   className="img-fluid"/>
                </div>
              </Link>
          </div>  
          <div className="col-md-8"> 
          <Link to={`/Bookpreview/${item.books_id}`}>    
            <h2>{item.title}</h2>  
          </Link>
                <h6 className="bann"> By: <span> {item.name} </span> </h6>
                <div className="desc2" >
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>  
          </div>  
            
          </div>      
        )) }

          </div>
          </div>
          <div className="col-md-9">
        {isimpresive.slice(0,2).map((item, index) => ( 
          <div  key={item.books_id} className="Recommended__homes__centers" > 
          <Link to={`/Bookpreview/${item.books_id}`}>
          <div className="col-md-8 bosss">
          <div className=" Recommended__cols">
              <div className="Recommended__collefts">
              <h2>{item.title}</h2> 
                <h6 className="bann"> By: <span> {item.name} </span> </h6>
                {item.price !== 0 && <h6 className="Price"> Price: <span> ${item.price} </span> </h6> }
                <p>Category : <span> {item.category === 'other' ? <>{item.other}</> :  <>{item.category}  </>} </span></p>
              </div>
              
              </div>
              </div>
              <div className="col-md-4 bosss">
          <div className="Recommended__colrights">
                  <div className="Recommended__homes__cover">
                    <img src={BaseURLuploads+item.imagefront} alt="books"   className="img-fluid"/>
                  </div>
              </div>
              </div>
            <div className="col-md-12 bosss">
            
              <div className="clearfix"></div>
              <div className="Recommended__homes__content" dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
            </Link>
          </div>      
        )) }
        </div>
     
      </>: <>
        <div className="col-md-12" >
          <ContentLoader height={500} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
            <rect x="37" y="77" rx="0" ry="0" width="627" height="47"/> 
            <rect x="37" y="137" rx="0" ry="0" width="627" height="237"/> 
            <rect x="37" y="400" rx="0" ry="0" width="237" height="60"/> 
            <rect x="900" y="77" rx="0" ry="0" width="287" height="377"/> 
          </ContentLoader> 
        </div>
      </> }
    </div>
  </div>
</div>

<div className="clearfix"></div>
<div className="topslider62 Highlighted__homes">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1> Noteworthy <span className="cols">  </span>  </h1>
      </div>
     
      {ishighlighted.length > 0 ? <>
        {ishighlighted.slice(0,4).map((item, index) => ( 
          <div key={item.books_id} className="col-md-3">   
          <div className="Highlighted__homes__sec">   
            <Link to={`/Bookpreview/${item.books_id}`}>
              <div className="coverDesign2">
                <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
              </div>
              <h2>{item.title}</h2>  
              {item.price !== 0 ? <h6 className="Price"> Price: <span> ${item.price} </span> </h6> : <h6 className="Price">  &nbsp; </h6>}
              <div className="Highlighted__homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
            </Link>
          </div>      
          </div>      
        )) }
      </>: <>
      {loaderhighlighted.map((item, index) => ( 
        <div className="col-md-3" key={ index } style={{padding:0}}>  
          <ContentLoader height={500} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
            <rect x="47" y="77" rx="0" ry="0" width="183" height="277"/> 
          </ContentLoader> 
        </div>
      )) }
    </> }
   
    </div>
  </div>
</div>

<div className="clearfix"></div>
   
<div className="topslider63 Latest__Homes">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1>  Audio <span className="cols">Books</span>, Publishers <span className="cols">List </span>& Latest <span className="cols"> Books </span> </h1>
      </div>
      <div className="clearfix"></div>
      <Tabs defaultTab="vertical-tab-one"  className="vertical-tabs">
        <div className="col-md-12" style={{padding:0}}>   
          <TabList>
            <Tab tabFor="vertical-tab-one"> Audio Books </Tab>
            <Tab tabFor="vertical-tab-two"> Publishers </Tab>
            <Tab tabFor="vertical-tab-three"> Latest Books</Tab>
          </TabList>
        </div>
        <div className="col-md-12 " style={{padding:0}}> 
          <div className="taaa"> 
            <TabPanel tabId="vertical-tab-one">
              {databookAudio.length > 0 ? <>
                {databookAudio.map((item, index) => ( 
                  <div  key={item.audio_id} className="col-md-3">  
                  <div className="Latest__Homes__audio">  
                      <Link to={`/AudioBookPreview/${item.audio_id}`}>
                        <div className="coverDesign2tab">
                          <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
                        </div>
                        <h2>{item.title}</h2>  
                        {item.price !== 0 ? <h6 className="Price"> Price: <span> ${item.price} </span> </h6> : <h6 className="Price">  &nbsp; </h6>}
                        <div className="Latest__Homes__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                      </Link>
                    </div>    
                    </div>    
                )) }
              </>:  null }
            </TabPanel>

            <TabPanel tabId="vertical-tab-two">
              {databookPublisher.length > 0 ? <>
                {databookPublisher.map((item, index) => ( 
                  <div  key={item.publisher_id} className="imsss">  
                    <div className="col-md-4">   
                    <div className="Latest__Homes__audio">   
                      <Link to={`/PublisherPreview/${item.publisher_id}`}>
                        <div className="coverDesign2tabqqq">
                          <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
                        </div>
                        <h2>{item.title}</h2>  
                        <div className="Publisher__home__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                      </Link>
                    </div>    
                    </div>    
                  </div>      
                )) }
              </>:  null }
            </TabPanel>

            <TabPanel tabId="vertical-tab-three">
              {isallbookslatest.length > 0 ? <>
                {isallbookslatest.map((item, index) => ( 
                  <div key={item.books_id} className="col-md-3 imsss">  
                  <div className="Latest__Homes__audio">   
                      <Link to={`/Bookpreview/${item.books_id}`}>
                        <div className="coverDesign2tabqqq2">
                          <img src={BaseURLuploads+item.imagefront} alt="books"   className="img-fluid"/>
                        </div>
                        <h2>{item.title}</h2>  
                        {item.price !== 0 ? <h6 className="Price"> Price: <span> ${item.price} </span> </h6> : <h6 className="Price">  &nbsp; </h6>}
                        <div className="Publisher__home__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,1700) }} />
                      </Link>
                    </div>   
                  </div>      
                )) }
              </>:  null }
            </TabPanel>
          </div>
      </div>
    </Tabs>

    </div>
  </div>
</div>




<div className="clearfix"></div>
<div className="topslider65 Featured__home">
  <div className="container">
    <div className="row">
      <div className="col-md-12">   
        <h1>  <span className="cols"> Featured </span> picks  </h1>
      </div>
      <div className="col-md-12">   
        {databookFeatured.length > 0 ? <>
          {databookFeatured.map((item, index) => ( 
            <div  key={item.books_id} className="col-md-6 sssqqsmmnnn">  
              <div className=" Featured__home__sec " >   
                <Link to={`/Bookpreview/${item.books_id}`}>
                  <div className="bookscovers">
                    <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
                  </div>
                  <h2>{item.title}</h2>  
                  {item.price !== 0 ? <h6 className="Price"> Price: <span> ${item.price} </span> </h6> : <h6 className="Price">  &nbsp; </h6>}
                  <div className="Featured__home__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
                 <button className="Featured__home__base"> View </button>
                </Link>
              </div>    
            </div>      
          )) }
        </>: <>
          {loaderfeatured.map((item, index) => ( 
            <div className="col-md-3" key={ index } style={{padding:0}}>  
              <ContentLoader height={217} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                <rect x="0" y="0" rx="0" ry="0" width="126" height="190"/> 
              </ContentLoader> 
            </div>
          )) }
        </> }
      </div>
    </div>
  </div>
</div>
<div className="clearfix"></div>
<div className="topslider64 Video__home">
  <div className="container">
    <div className="row">
     
      <div className="col-md-12">
        <h1>Book <span className="cols"> Trailers </span>  </h1>
        <h6>Collection of video trailers from our author members</h6>
      </div>
      <div className="col-md-12 topslider62videos" >
        {databooktrailers.length > 0 ? <>
          {databooktrailers.map((item, index) => ( 
            <div className="col-md-4" key={item.video_id} >
              {item.url.match(regExp) ?  <> 
                {item.url.match(regExp).length >= 2 ? 
                  <iframe id="ytplayer" type="text/html" className="videosss2" src={`https://www.youtube.com/embed/` + item.url.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`} frameBorder="0" allowFullScreen></iframe>
                : null }
              </>
                : null 
              } 
            </div>
          )) }
        </>: <>
          {loadervideo.map((item, index) => ( 
            <div className="col-md-4" key={ index } style={{padding:0}}>  
              <ContentLoader height={237} width={1100} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                <rect x="0" y="0" rx="0" ry="0" width="320" height="190"/> 
              </ContentLoader> 
            </div>
          )) }
        </> }
       
      </div>
    </div>
  </div>
</div>
   
<div className="clearfix"></div>
<div className="topslider66 Standard__home">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1> List <span className="cols">   </span>   </h1>
      </div>
   
      {databookStandard.length > 0 ? <>
        {databookStandard.slice(0,12).map((item, index) => ( 
          <div key={item.books_id} className="col-md-3" >   
          <div className="Standard__home__sec" >   
     
            <Link to={`/Bookpreview/${item.books_id}`}>

              <div className="bookscovers">
                <img src={BaseURLuploads + item.imagefront} alt="books"   className="img-fluid"/>
              </div>
              <h2>{item.title}</h2>  
              {item.price !== 0 ? <h6 className="Price"> Price: <span> ${item.price} </span> </h6> : <h6 className="Price">  &nbsp; </h6>}
              <div className="Standard__home__content" dangerouslySetInnerHTML={{ __html: item.description.substring(0,700) }} />
            </Link>  
          </div>      
          </div>      
        )) }
      </>: <>
          {loaderstandard.map((item, index) => ( 
            <div className="col-md-3" key={ index } style={{padding:0}}>  
              <ContentLoader height={237} width={1349} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb" > 
                <rect x="0" y="0" rx="0" ry="0" width="138" height="206"/> 
              </ContentLoader> 
            </div>
          )) }
      </> }
    
    </div>
  </div>
</div>

<div className="clearfix"></div>


  </div>
         
  );
}

export default Home;